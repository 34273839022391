import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class OTPLoginService {
    constructor(private http: HttpClient) {
    }

    public storeActivationCodeAndRole(tablePassword: string, role: string, enablePayment, excludedCategories, assignedEmployee?,
                                      isPermanent = false, expireAt?) {
        this.http.post(`${environment.apiUrl}/users/storeActivationCode`, {
            code: tablePassword,
            role: role,
            expireAt: expireAt,
            enablePayment: enablePayment,
            excludedCategories: excludedCategories,
            assignedEmployee: assignedEmployee
        }).subscribe((response) => {
            },
            (error) => {
                throwError('Error in Storing activation Code');
            }
        );
    }

    public checkActivationCode(activationCode) {
        return this.http.get<any>(`/api/users/getActivationCodeAndRole/${activationCode}`);
    }
}

