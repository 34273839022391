import {Reward} from "./reward";
import {OrgTable} from "./orgTable";
import {UserTasks} from "./userTasks";
import {LineItem} from './lineItem';
import {Time} from '@angular/common';
import { Address } from "./address.model";

export class OrgSetting {
  _id: string;
  orgUuid: string;
  name: string;
  image: string;
  heroImage: string;
  email: string;
  accountType: string;
  phoneNumber: number;
  merchantId: string;
  customerReviewUrl: string;
  currency_code: string;
  uniqueOrgIdent: string;
  userTasks: UserTasks[];
  featuresEnabled: {
    serverTableMove: boolean;
    jukeboxEnabled: boolean;
    reservationsEnabled: boolean;
    marketingEnabled: boolean;
    hideGratuityOnTable: boolean;
    serverOrderEdit: boolean;
    v1Print: boolean;
    addressEnabled: boolean;
    websiteEnabled: boolean;
  };
  orderTypesEnabled: {
    carryout: boolean;
    dineIn: boolean;
    delivery: boolean;
    deliveryFee: LineItem;
    deliveryRadius: number;
  };
  businessHours: {
    monday: {
      openTime: Time;
      closeTime: Time;
    },
    tuesday: {
      openTime: Time;
      closeTime: Time;
    },
    wednesday: {
      openTime: Time;
      closeTime: Time;
    },
    thursday: {
      openTime: Time;
      closeTime: Time;
    },
    friday: {
      openTime: Time;
      closeTime: Time;
    },
    saturday: {
      openTime: Time;
      closeTime: Time;
    },
    sunday: {
      openTime: Time;
      closeTime: Time;
    },
  };
  tables: OrgTable[];
  additionalLayouts: any;
  rewards: Reward;
  address: Address;
  modifiedDate: Date;
  createdDate: string;
  temiPosition: {
    x: number,
    y: number,
    yaw: number,
    tiltAngle: number
  };
}
