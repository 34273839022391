import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-new-device-login',
  template: `
    <div>
      <h3>{{ title }}</h3>
      <p>{{ message }}</p>
      <button mat-raised-button color="primary" (click)="onDone()">{{ button_text }}</button>
    </div>
  `,
  styles: [`
    /* Add specific styles here */
  `]
})
export class NewDeviceLoginComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() button_text: string;
  @Input() popupType: string;

  onDone() {
  }
}
