// login-code.component.ts
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AESEncryptDecryptService } from '../../_services/aesencrypt-decrypt.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Preferences } from '@capacitor/preferences';
import { Constants } from 'src/app/constants/constants';
import {OTPLoginService} from '../../_services/OTPLogin.service';
import {environment} from '../../../environments/environment';
import {UtilsService} from '../../_services/utils.service';
import {Location} from '@angular/common';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-login-code',
  templateUrl: './login-code.component.html',
  styleUrls: ['./login-code.component.scss']
})


export class LoginCodeComponent {
  activationCode = '';
  generatedCode = '';
  isActivated = false;
  isCodeVisible = false;
  Constants = Constants;


  constructor(
    private http: HttpClient,
    private router: Router,
    private utilsService: UtilsService,
    private location: Location,
    private notifier: NotifierService,
    private otpLoginService: OTPLoginService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private authenticationService: AuthenticationService,

  ) { }

  ngOnInit() {
    this.performUrlParamsInit();
  }



  private performUrlParamsInit() {
    const url_string = window.location.href;
    const url = new URL(url_string);

    if (url.searchParams.get('table')) {
      localStorage.setItem('table', url.searchParams.get('table'));
    }
    if ((url.searchParams.get('code'))) {
      this.activationCode = url.searchParams.get('code');
      this.onActivate();
    }
    this.deleteQueryParameterFromCurrentRoute();
  }

  onActivate() {

    if (!this.activationCode) {
      this.showNotification('warning', 'Please enter an activation code.');
      return;
    }
    this.otpLoginService.checkActivationCode(this.activationCode).subscribe(
      async user => {
        if (user?.user) {
          await this.setCapacitorUser(this._AESEncryptDecryptService.encrypt(JSON.stringify(user.user)));
          this.authenticationService.role = user.user.role[0];
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.utilsService.setCurrentUser(user.user, user.user.role[0]);
          this.authenticationService.setUser(user.user);
          this.successfulActivationRoute(user.user);
        } else if (user) {
          await this.setCapacitorUser(this._AESEncryptDecryptService.encrypt(JSON.stringify(user)));
          this.authenticationService.role = user.role[0];
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.utilsService.setCurrentUser(user, user.role[0]);
          this.authenticationService.setUser(user);
          this.successfulActivationRoute(user);
        }
        if (user?.redirectURL) {
          const url = new URL(user.redirectURL);
          user.redirectURL = url.toString();
          window.location.assign(user.redirectURL);
        }
      },
      error => {
        this.showNotification('error', error);
        console.error('Error retrieving role:', error);
      }
    );
  }

  private successfulActivationRoute(user: any) {
            if (user.role[0] === Constants.TABLE) {
              this.router.navigate(['/tableSetup']);
            } else if (user.role[0] === Constants.KIOSK) {
              this.router.navigate(['/kiosk']);
            } else {
              console.error('Unrecognized role.');
            }
  }

  async setCapacitorUser(value: string) {
    await Preferences.set({
      key: 'user',
      value: value
    });
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  deleteQueryParameterFromCurrentRoute() {
    // this.location.replaceState('./order');
    this.location.replaceState(window.location.href.split('?')[0].split(window.location.href.split('/')[2])[1]);
  }
}
