import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivationCode } from '../_models/activation-code.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivationCodeService {

  constructor(private http: HttpClient) {}

  getPermanentCodes(): Observable<ActivationCode[]> {
    console.log('in get permanent codes service');
    console.log(`${environment.apiUrl}/permanentCodes`);
    return this.http.get<ActivationCode[]>(`${environment.apiUrl}/users/permanentCodes`);
  }
  deleteActivationCode(code: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/users/activation-codes/${code}`);
  }
  storeOtp(payload): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/storeActivationCode`, payload);
  }

  getOtpDetails(code: string): Observable<ActivationCode> {
    return this.http.get<ActivationCode>(`${environment.apiUrl}/users/getActivationCodeAndRole/${code}`);
  }




}
