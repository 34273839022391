import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {Category} from '../../../_models/category';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ActivationCodeService} from '../../../_services/activation-code.service';
import {ActivationCode} from '../../../_models/activation-code.model';
import {NotifierService} from 'angular-notifier';
import * as _ from 'lodash';
import {User} from '../../../_models';
import {TakePayment} from '../../../_models/takePayment';
import {environment} from '../../../../environments/environment';
import {first} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Constants} from 'src/app/constants/constants';
import {UserService} from '../../../_services';
import {UtilsService} from '../../../_services/utils.service';
import {OTPLoginService} from '../../../_services/OTPLogin.service';
import * as uuid from 'uuid';
import {SquareApiService} from '../../../_services/square-api.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SquareDevicePairingPopupComponent} from '../../square-device-pairing-popup/square-device-pairing-popup.component';
import {SocketioService} from '../../../_services/socketio.service';
import {CloverPayServiceService} from '../../../_services/clover-pay-service.service';

@Component({
    selector: 'app-otp-dialog',
    templateUrl: './otp-dialog.component.html',
    styleUrls: ['./otp-dialog.component.scss']
})
export class OtpDialogComponent implements OnInit {
    Constants = Constants;
    public environment = environment;
    @Input() data: { enablePayment: boolean; excludedCategories: string[]; assignedEmployee: string; role: string };
    @Output() saveEvent = new EventEmitter<{ otp: string; enablePayment: boolean; excludedCategories: string[]; assignedEmployee: string; role: string; isPermanent: boolean; expireAt: Date | null }>();
    permanentCodes: ActivationCode[] = [];
    errorMessage = '';
    kioskData = {
        otp: '',
        enablePayment: false,
        creditCardPayment: false,
        scanToPayPayment: false,
        cloverConnectorPayment: false,
        cloverDeviceId: '',
        cloverDeviceSerial: '',
        deviceAlias: '',
        excludedCategories: [],
        assignedEmployee: null,
        role: 'Table',
        isPermanent: false,
        isTemporary: true,
        expiresAt: null,
    };

    otpGenerated = false;
    isSaveDisabled = false;
    cloverCategories: Category[] = [];
    employees: any[] = [];
    viewType = 'tableInstructions';
    tableUsername = '';
    tableAutoLoginURL: string;
    qrUrl: string;
    table: User;
    selectedRole: string;
    currentUser: any;
    siteURL = `https://${environment.subdomainUrl.substring(1)}/login`;
    permCode;
    tables: any;
    websiteUrl: string;
    selectedPaymentType = 'qrPayment';
    cloverPaymentDevices = [];
    locations = [];

    constructor(private otpService: OTPLoginService, private userService: UserService, private utilsService: UtilsService, private http: HttpClient, private cloverApiService: CloverApiService, private notifier: NotifierService,
                private activationCodeService: ActivationCodeService,
                private squareApi: SquareApiService,
                private dialog: MatDialog,
                private cloverPayService: CloverPayServiceService,
                private socketIOService: SocketioService,
                private cdRef: ChangeDetectorRef) {
        this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser?.selectedRole;
    }

    excludedDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'name',
        textField: 'name',
        itemsShowLimit: 2,
        allowSearchFilter: true,
        enableCheckAll: false
    };

    employeeDropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        enableCheckAll: false
    };

    ngOnInit(): void {
        if (this.data) {
            this.kioskData = {...this.kioskData, ...this.data};
        }
        this.loadEmployees();
        this.loadCategories();
        this.getOrCreateTableLogin();
        this.getAllTables();
        if (this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            this.getConnectedCloverDevices();
        } else if (this.currentUser.orgSettings.accountType === Constants.SQUARE) {
            this.getMerchantLocations();
            this.loadAllCategoriesSquare();
        }
    }

    ngOnChanges(): void {
        if (this.viewType === 'manageDevices') {
            this.loadPermanentCodes();
        }
    }

    getMerchantLocations() {
        this.squareApi.getMerchantLocations().subscribe((locations) => {
            this.locations = locations;
        });
    }

    resetPassword() {
        if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager') {
            const passwordInput = prompt('Enter New Password:');
            if (passwordInput !== null && passwordInput !== '') {
                if (passwordInput.length > 6) {
                    this.table.password = passwordInput.toString();
                    this.userService.update(this.table).pipe(first()).subscribe(() => {
                            this.getOrCreateTableLogin();
                        },
                        error => {
                            this.notifier.notify('error', 'Error Resetting Password.');
                        });
                } else {
                    throwError('Password too short');
                }
            }
        } else {
            throwError('You are not authorized to perform this action!');
        }
    }

    isNil(obj) {
        return _.isNil(obj);
    }


    generateOTP(): string {
        return Math.random().toString(36).slice(-6).toUpperCase();
    }

    getOrCreateTableLogin(update?) {
        const user = new User();

        if (update) {
            const takePayment = new TakePayment();
            takePayment.paymentRequired = this.kioskData.enablePayment;
            takePayment.devicePaymentEnabled = this.kioskData.enablePayment;
            takePayment.scanToPayPayment = this.kioskData.enablePayment;
            user.excludedCategories = this.kioskData.excludedCategories;
            user.takePayment = takePayment;
        }
        this.kioskData.otp = Math.random().toString(36).slice(-6);
        this.otpService.storeActivationCodeAndRole(this.kioskData.otp, Constants.TABLE, this.kioskData.enablePayment, this.kioskData.excludedCategories);

        this.userService.getOrCreateTableLogin(this.kioskData.otp, update ? user : undefined).subscribe(table => {
            // this.kioskData.enablePayment = table.takePayment.devicePaymentEnabled;
            this.tableUsername = table.username;
            this.websiteUrl = 'https://' + this.currentUser.orgSettings.uniqueOrgIdent.substring(this.currentUser.orgSettings.uniqueOrgIdent.indexOf('@') + 1, this.currentUser.orgSettings.uniqueOrgIdent.indexOf('.com')) + `${environment.websiteSubdomainUrl}`; // this.qrUrl + '&website=true';

            // this.kioskData.excludedCategories = table.excludedCategories;
            if (!environment.production) {
                this.tableAutoLoginURL = `https://dine-n-go-dev.herokuapp.com/login-code?code=` + this.kioskData.otp;
                this.qrUrl = `https://dine-n-go-dev.herokuapp.com/login-code?code=` + this.kioskData.otp;
            } else {
                this.tableAutoLoginURL = `https://dinengo.net/login-code?code=` + this.kioskData.otp;
                this.qrUrl = `https://dinengo.net/login-code?code=` + this.kioskData.otp;
            }
            this.table = table;
        });
    }

    generateNewOtp(): void {
        this.kioskData.otp = this.generateOTP();
    }


    onPermanentChange(): void {
        if (this.kioskData.isPermanent) {
            this.kioskData.isTemporary = false;
        }
    }

    onTemporaryChange(): void {
        this.kioskData.isPermanent = !this.kioskData.isTemporary;
        this.generateNewOtp();
        // if (this.kioskData.isTemporary) {
        //   this.kioskData.isPermanent = false;
        // }
    }

    onRoleChange(): void {
        this.resetSaveButtonState();
        // this.generateOTPForRole(this.kioskData.role);
        this.kioskData.enablePayment = false;
        this.kioskData.creditCardPayment = false;
        if (this.kioskData.role === Constants.KIOSK && this.kioskData.enablePayment) {
            this.kioskData.scanToPayPayment = true;
        } else {
            this.kioskData.scanToPayPayment = false;
        }
        this.kioskData.cloverConnectorPayment = false;
        this.kioskData.cloverDeviceId = '';
        this.kioskData.deviceAlias = '';
        this.kioskData.excludedCategories = [];
        this.kioskData.assignedEmployee = null;
    }

    isSelectedRole(role) {
        return this.kioskData.role === role;
    }

    onEnablePaymentChange(event): void {
        // this.resetSaveButtonState();
        if (this.kioskData.role === Constants.KIOSK && this.kioskData.enablePayment) {
            this.paymentTypeChange('qrPayment');
            this.kioskData.scanToPayPayment = true;
        }
    }

    onExcludedCategoriesChange(): void {
        this.resetSaveButtonState();
    }

    onAssignedEmployeeChange(): void {
        this.resetSaveButtonState();
    }

    private resetSaveButtonState(): void {
        this.isSaveDisabled = false;
        this.otpGenerated = false;
    }

    loadEmployees(): void {
        this.cloverApiService.getAllEmployees().subscribe(
            employees => {
                // console.log('Fetched Employees:', employees);
                this.employees = employees;
                this.cdRef.detectChanges();
            },
            error => console.error('Error loading employees:', error)
        );
    }

    loadCategories(): void {
        this.cloverApiService.getAllCategories().subscribe(
            categories => {
                this.cloverCategories = categories,
                    this.cdRef.detectChanges();
                // console.log("Fetched Categories:", categories);
            },
            error => console.error('Error loading categories:', error)
        );
    }

    loadPermanentCodes(): void {
        this.activationCodeService.getPermanentCodes().subscribe(
            (codes: ActivationCode[]) => {
                this.permCode = codes.filter(code =>  code.isPermanent)[0]?.code;
                if (this.permCode) {
                    this.tableAutoLoginURL = `https://` + environment.subdomainUrl.substring(1) + `/login-code?code=` + this.permCode;
                    this.qrUrl = `https://` + environment.subdomainUrl.substring(1) + `/login-code?code=` + this.permCode;
                }
                this.permanentCodes = codes;
            },
            error => {
                console.error('Error fetching permanent codes:', error);
                this.permanentCodes = [];
            }
        );
    }

    deleteCode(code: string): void {
        // const confirmDelete = confirm(`Are you sure you want to delete the code: ${code}?`);
        // if (confirmDelete) {
        this.activationCodeService.deleteActivationCode(code).subscribe(
            (response) => {
                this.notifier.notify('success', 'Code Successfully Deleted');
                this.loadPermanentCodes();
            },
            (error) => {
                this.notifier.notify('error', 'Error Deleting Code');
            }
        );
        // }
    }

    onViewChange(view: string): void {
        console.log('Switching view to ', view);
        this.viewType = view;

        if (view === 'manageDevices') {
            this.loadPermanentCodes();
        }
    }

    generatePermanentCode(): void {
        const otp = `${Math.random().toString(36).slice(-6).toUpperCase()}`;
        this.kioskData.otp = otp;

        const payload = {
            code: otp,
            role: this.kioskData.role,
            expiresAt: null, // Permanent code has no expiry
            isPermanent: true, // Permanent code has no expiry
            enablePayment: this.kioskData.enablePayment,
            assignedEmployee: this.kioskData.assignedEmployee,
            excludedCategories: this.kioskData.excludedCategories,
            creditCardPayment: this.kioskData.creditCardPayment,
            scanToPayPayment: this.kioskData.scanToPayPayment,
            cloverConnectorPayment: this.kioskData.cloverConnectorPayment,
            cloverDeviceId: this.kioskData.cloverDeviceId,
            cloverDeviceSerial: this.kioskData.cloverDeviceSerial,
            deviceAlias: this.kioskData.deviceAlias,
        };

        this.activationCodeService.storeOtp(payload).subscribe(
            (response) => {
                console.log('Permanent OTP stored successfully:');
                this.otpGenerated = true;
            },
            (error) => {
                this.notifier.notify('error', 'Error generating permanent OTP:');
            }
        );
    }

    goToLink(url: string) {
        window.open(url, '_blank');
    }

    getAllTables() {
        this.tables = this.currentUser.orgSettings.tables;
    }

    paymentTypeChange(type?) {
        if (type === 'creditCardPayment') {
            this.kioskData.creditCardPayment = true;
            this.kioskData.scanToPayPayment = false;
            this.kioskData.cloverConnectorPayment = false;
        } else if (type === 'cloverConnector') {
            this.kioskData.creditCardPayment = false;
            this.kioskData.scanToPayPayment = false;
            this.kioskData.cloverConnectorPayment = true;
            if (this.currentUser.orgSettings.accountType === Constants.SQUARE) {
                this.createDeviceCode();
            }
        }  else if (type === 'qrPayment') {
            this.kioskData.creditCardPayment = false;
            this.kioskData.scanToPayPayment = true;
            this.kioskData.cloverConnectorPayment = false;
        } else {
            this.kioskData.creditCardPayment = false;
            this.kioskData.scanToPayPayment = false;
            this.kioskData.cloverConnectorPayment = false;
        }
    }

    createDeviceCode() {
        const request = {
            idempotency_key: uuid.v4(),
            device_code: {
                name: 'Counter 1',
                product_type: 'TERMINAL_API',
                location_id: this.currentUser.locationId
            }
        };

        this.squareApi.createDeviceCode(request).subscribe((response) => {
            this.showDevicePairingPopup('Pairing A Square Device For Payments', 'Enter the following pairing code on your device to login and pair device: ', response.device_code.code);
        });
    }

    showDevicePairingPopup(description: string, message: string, code: string) {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            message: message,
            description: description,
            code: code
        };

        const dialogRef = this.dialog.open(SquareDevicePairingPopupComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
            if (!_.isNil(data) && data.event === 'Cancel') {
                this.kioskData.scanToPayPayment = true;
                this.kioskData.cloverDeviceId = '';
                this.kioskData.deviceAlias = '';
                return;
            }
            this.kioskData.cloverDeviceId = this.socketIOService.squareDeviceId;
            this.kioskData.deviceAlias = this.socketIOService.deviceAlias;
            if (!_.isNil(this.kioskData.cloverDeviceId) || !_.isNil(this.kioskData.deviceAlias)) {
                this.kioskData.scanToPayPayment = false;
                this.kioskData.cloverConnectorPayment = true;
                this.paymentTypeChange('squareConnectorPayment');
            }
        });
    }

    addTableToAutologin(item: any) {

        if (this.tableAutoLoginURL.includes('?table=')) {
            this.tableAutoLoginURL = this.tableAutoLoginURL.substring(0, this.tableAutoLoginURL.indexOf('?table='));
        }
        this.tableAutoLoginURL = this.tableAutoLoginURL + '?table=' + item.replace(/\s/g, '');

        if (this.qrUrl.includes('&table=')) {
            this.qrUrl = this.qrUrl.substring(0, this.qrUrl.indexOf('&table='));
        }
        this.qrUrl = this.qrUrl + '&table=' + item.replace(/\s/g, '');
    }

    getConnectedCloverDevices() {
        this.cloverPayService.getCloverConnectedDevices().subscribe(response => {
            this.cloverPaymentDevices = [];
            this.cloverPaymentDevices = response;
        });
    }

    setDeviceSerial() {
        const clientId = this.kioskData.cloverDeviceId;
        this.cloverPaymentDevices.map(device => {
            if (device.id === clientId) {
                this.kioskData.cloverDeviceSerial = device.serial;
            }
        });
    }

    private loadAllCategoriesSquare() {
        this.squareApi.getAllCategories().subscribe(category => {
            this.cloverCategories = category;
        });
    }
}
