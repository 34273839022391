import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {OrgSetting} from "../_models/orgSetting";

@Injectable({ providedIn: 'root' })
export class OrgSettingsService {

  orgSettings: Observable<OrgSetting>;

  constructor(private http: HttpClient) { }

  getOrgSettings() {
    if (_.isNil(this.orgSettings)) {
      const response = this.http.get<OrgSetting>(`${environment.apiUrl}/orgSettings/settings`);
      this.orgSettings = response;
      return response;
    } else {
      return this.orgSettings;
    }
  }


  createOrgSetting(setting) {
      return this.http.post<OrgSetting>(`${environment.apiUrl}/orgSettings/create`, setting);
  }

  deleteCloverToken() {
      return this.http.delete<OrgSetting>(`${environment.apiUrl}/orgSettings/deleteToken`);
  }

  updateSettings(setting: OrgSetting) {
    return this.http.post(`${environment.apiUrl}/orgSettings/update`, setting);
  }

  updateWithoutWebhook(setting: OrgSetting) {
    return this.http.post(`${environment.apiUrl}/orgSettings/updateWithoutWebhook`, setting);
  }

  delete() {
    return this.http.delete(`${environment.apiUrl}/orgSettings/delete`);
  }
}

