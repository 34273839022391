<div class="d-flex justify-content-center p-2">
  <h1 class="font-weight-bold">Jukebox Login</h1>
</div>
<mat-dialog-content>
  <div class="dialog-options-container">
    <mat-button-toggle-group [(ngModel)]="popupType" aria-label="Font Style">
      <mat-button-toggle value="link">Manual Login</mat-button-toggle>
      <mat-button-toggle value="qr">Login with QR Code</mat-button-toggle>
    </mat-button-toggle-group>
    <!--      <p>Chosen value is {{popupType}}</p>-->
    <div class="error-message">{{errorMessage}}</div>
  </div>
  <div class="text-container-qr" *ngIf="popupType === 'qr'">
    <ng-container *ngTemplateOutlet="qrscanner"></ng-container>
  </div>
  <div class="text-container" *ngIf="popupType === 'link'">
    <ng-container *ngTemplateOutlet="manualLogin"></ng-container>
  </div>
</mat-dialog-content>

<ng-template #qrscanner>
  <div class="card p-3">
    <div class="d-flex justify-content-center">
      <h1 class="card-text">Instructions</h1>
    </div>
    <p class="card-text pt-3">To Login: Scan the QR code with the device you would like to login.</p><br>
    <app-qr-generator [height]="200" [width]="200" [qrUrl]="siteAutoLoginURL"
      [title]="('orderConfirmation.paymentQrTitle' | translate)"></app-qr-generator>
  </div>
</ng-template>

<ng-template #manualLogin>
  <div class="card p-3">
    <div class="d-flex justify-content-center pb-3">
      <h1 class="card-text">Jukebox Manual Login</h1>
    </div>
    <div class="creds-container">
      <a class="link pr-3 pl-3" (click)="goToLink(siteURL)" href="#">URL</a>
      <a class="link" href="#" (click)="goToLink(siteAutoLoginURL)">Auto login URL</a>
      <div class="general-padding">Username: <span class="creds">{{tableUsername}}</span> </div>
      <div class="general-padding">One Time Password: <span class="creds"> &nbsp; {{tablePassword}} &nbsp; &nbsp; <b
            style="font-weight: bold;">OR</b> &nbsp; &nbsp; Admin's Password</span>
        &nbsp; &nbsp;
        <a class="password-reset" *ngIf="false" alt="password Reset">
          <mat-icon mat-icon class="delete-icon">lock_reset</mat-icon>
        </a>
      </div>
    </div>
  </div>
</ng-template>
