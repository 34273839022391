import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {Constants} from '../../../constants/constants';
import {QrDeviceService} from '../../../_services/qrDevice.service';
import {QRModel} from '../../../_models/qrDevice';
import {environment} from '../../../../environments/environment';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {Category} from '../../../_models/category';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {TakePayment} from '../../../_models/takePayment';
import {QRCodeComponent} from 'angularx-qrcode';
import {UtilsService} from '../../../_services/utils.service';
import {SelectDeliveryFeeItemPopupComponent} from '../select-delivery-fee-item-popup/select-delivery-fee-item-popup.component';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {first} from 'rxjs/operators';
import {Reward} from '../../../_models/reward';
import {OrgSetting} from '../../../_models/orgSetting';
import {OrgSettingsService} from '../../../_services/orgSettings.service';
declare const google;

@Component({
    selector: 'app-qr-login-notification-popup',
    templateUrl: './QR-code-login-popup.html',
    styleUrls: ['./QR-code-login-popup.component.scss']
})
export class QRCodeLoginPopupComponent implements OnInit {

    @ViewChild('qrcode', {static : true}) qrcode: QRCodeComponent;

    username = new FormControl('', [Validators.required]);

    description: string;
    message: string;
    currentUser: User;
    currentTableName: string;
    errorMessage: string;
    selectedRole: string;
    paymentEnabled = false;
    loading = false;
    assignedTables = false;
    imageUpdated = false;
    stringAddress = '';
    table: User;
    button_text = 'Submit';
    popupType = 'website';
    siteURL = `https://dinengo.net`;
    siteAutoLoginURL = `https://dinengo.net/login?`;
    websiteUrl: string;
    qrUrl = `https://dinengo.net/cloverAuth/cloverAuth?qr=`;
    tableUsername = '';
    tablePassword = Math.random().toString(36).slice(-8);
    cloverCategories: Category[];
    excludedCategories: any[] = [];
    selectedCategories: any[] = [];

    @ViewChild('deliveryToggle') deliveryToggle: MatSlideToggle;

    excludedDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'name',
        textField: 'name',
        itemsShowLimit: 2,
        allowSearchFilter: true,
        enableCheckAll: false
    };
    orgSettingForm: any;
    private heroImage: string;
    private submitted = false;

    constructor(private dialogRef: MatDialogRef<QRCodeLoginPopupComponent>, private cloverOrderService: CloverOrderService,
                private alertService: AlertService, private userService: UserService, private orgService: OrgSettingsService, private router: Router,
                private cloverAPIService: CloverApiService, private utilsService: UtilsService, private qrService: QrDeviceService,
                private _AESEncryptDecryptService: AESEncryptDecryptService, private formBuilder: FormBuilder, private dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.title;
        this.message = data.message;
        this.button_text = data.button_text;
        this.currentTableName = data.currentTableName;
        this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser?.selectedRole;
        this.excludedCategories = this.currentUser.excludedCategories.length > 0 ? this.currentUser.excludedCategories : [];
        this.tableUsername = 'qr' + this.currentUser.orgSettings.uniqueOrgIdent;
        if (!environment.production) {
            this.siteURL = `https://dine-n-go-dev.herokuapp.com`;
            this.siteAutoLoginURL = 'https://dine-n-go-dev.herokuapp.com/login?';
            this.qrUrl = `https://dine-n-go-dev.herokuapp.com/cloverAuth/cloverAuth?qr=`;
        }
    }
    readonly Constants = Constants;

    ngOnInit(): void {
        if (this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            this.loadAllCategories();
        }
        this.generateQrCode();
        if (this.selectedRole === Constants.MANAGER || this.selectedRole === Constants.ADMIN) {
            this.setupOrgForm();
        }
    }

    private setupOrgForm() {
        this.orgSettingForm = this.formBuilder.group({
            name: [this.currentUser.orgSettings.name, Validators.required],
            image: [this.currentUser.orgSettings.image],
            heroImage: [this.currentUser.orgSettings.heroImage],
            phoneNumber: [this.currentUser.orgSettings.phoneNumber, Validators.required],
            customerReviewUrl: [this.currentUser.orgSettings?.customerReviewUrl || ''],
            email: [this.currentUser.orgSettings.email, Validators.required],
            uniqueOrgIdent: [{value: this.currentUser.orgSettings.uniqueOrgIdent, disabled: true}],
            featuresEnabled: this.formBuilder.group({
                serverOrderEdit: [this.currentUser.orgSettings.featuresEnabled?.serverOrderEdit],
                serverTableMove: [this.currentUser.orgSettings.featuresEnabled?.serverTableMove],
                jukeboxEnabled: [this.currentUser.orgSettings.featuresEnabled?.jukeboxEnabled],
                marketingEnabled: [this.currentUser.orgSettings.featuresEnabled?.marketingEnabled],
                hideGratuityOnTable: [this.currentUser.orgSettings.featuresEnabled?.hideGratuityOnTable],
                reservationsEnabled: [this.currentUser.orgSettings.featuresEnabled?.reservationsEnabled],
                v1Print: [this.currentUser.orgSettings.featuresEnabled?.v1Print],
                addressEnabled: [this.currentUser.orgSettings.featuresEnabled?.addressEnabled],
                websiteEnabled: [this.currentUser.orgSettings.featuresEnabled?.websiteEnabled]
            }),
            rewards: this.formBuilder.group({
                rewardsEnabled: [this.currentUser.orgSettings.rewards.rewardsEnabled],
                amountOff: [this.currentUser.orgSettings.rewards.amountOff ? this.currentUser.orgSettings.rewards.amountOff / 100 : 2],
                afterVisits: [this.currentUser.orgSettings.rewards.afterVisits ? this.currentUser.orgSettings.rewards.afterVisits : 3]
            }),
            address: this.formBuilder.group({
                line1: [this.currentUser.orgSettings.address.line1],
                line2: [this.currentUser.orgSettings.address.line2],
                city: [this.currentUser.orgSettings.address.city],
                state: [this.currentUser.orgSettings.address.state],
                country: [this.currentUser.orgSettings.address.country],
                postal_code: [this.currentUser.orgSettings.address.postal_code],
                longitude: [this.currentUser.orgSettings.address.longitude],
                latitude: [this.currentUser.orgSettings.address.latitude],
            }),
            orderTypesEnabled: this.formBuilder.group({
                carryout: [this.currentUser.orgSettings.orderTypesEnabled.carryout],
                dineIn: [this.currentUser.orgSettings.orderTypesEnabled.dineIn],
                delivery: [this.currentUser.orgSettings.orderTypesEnabled.delivery],
                deliveryFee: [this.currentUser.orgSettings.orderTypesEnabled?.deliveryFee],
                deliveryRadius: [this.currentUser.orgSettings.orderTypesEnabled?.deliveryRadius ?
                    this.currentUser.orgSettings.orderTypesEnabled?.deliveryRadius : 25]
            }),
            businessHours: this.formBuilder.group({
                monday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.monday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.monday.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.monday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.monday?.closeTime : '20:00']
                }),
                tuesday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.tuesday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.tuesday?.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.tuesday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.tuesday?.closeTime : '20:00']
                }),
                wednesday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.wednesday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.wednesday?.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.wednesday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.wednesday?.closeTime : '20:00']
                }),
                thursday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.thursday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.thursday?.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.thursday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.thursday?.closeTime : '20:00']
                }),
                friday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.friday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.friday?.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.friday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.friday?.closeTime : '20:00']
                }),
                saturday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.saturday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.saturday?.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.saturday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.saturday?.closeTime : '20:00']
                }),
                sunday: this.formBuilder.group({
                    openTime: [this.currentUser.orgSettings.businessHours?.sunday?.openTime ?
                        this.currentUser.orgSettings.businessHours?.sunday?.openTime : '11:00'],
                    closeTime: [this.currentUser.orgSettings.businessHours?.sunday?.closeTime ?
                        this.currentUser.orgSettings.businessHours?.sunday?.closeTime : '20:00']
                }),
            })
        });
        this.stringAddress = (this.currentUser.orgSettings.address.line1 + ' ' + this.currentUser.orgSettings.address.line2 + ' ' +
            this.currentUser.orgSettings.address.city + ', ' + this.currentUser.orgSettings.address.state?.toUpperCase() + ' ' +
            this.currentUser.orgSettings.address.postal_code).replace('null','');
    }

    initializeAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete') as HTMLInputElement,
            {
                types: ['address'],
                componentRestrictions: {country: 'us'}
            }
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            console.log('Selected place:', place);
            this.AddressChange(place);
        });
    }

    public AddressChange(address: any) {
        // setting address from API to local variable
        this.orgSettingForm.get('address')?.get('line1').setValue(address.address_components[0]?.long_name + ' ' + address.address_components[1]?.long_name);
        this.orgSettingForm.get('address')?.get('city').setValue(address.address_components[2]?.long_name);
        this.orgSettingForm.get('address')?.get('state').setValue(address.address_components[4]?.short_name);
        this.orgSettingForm.get('address')?.get('postal_code').setValue(address.address_components[6]?.long_name);
        this.orgSettingForm.get('address')?.get('country').setValue(address.address_components[5]?.short_name);
        this.orgSettingForm.get('address')?.get('longitude').setValue(address.geometry.location.lng());
        this.orgSettingForm.get('address')?.get('latitude').setValue(address.geometry.location.lat());
        this.stringAddress = address.formatted_address;
        this.orgSettingForm.markAsDirty();
    }

    private loadAllCategories() {
        this.cloverAPIService.getAllCategories().subscribe(category => {
            this.cloverCategories = category;
            this.loading = false;
            this.selectedCategories = this.getSelectedCategoriesList(this.excludedCategories ? this.excludedCategories : [], this.cloverCategories);
            this.excludedCategories = this.getSelectedCategoriesList(this.excludedCategories ? this.excludedCategories : [], this.cloverCategories).map( cloverCategory =>  cloverCategory.name);
        });
    }

    generateQrCode(update?, closeDialog?): void {
        this.loading = true;
        this.currentUser = this.utilsService.getCurrentUser();

        const qrDevice = new User();
        qrDevice.uuid = this.currentUser.uuid;
        qrDevice.username = 'qr' + this.currentUser.orgSettings.uniqueOrgIdent;
        qrDevice.orgSettings = this.currentUser.orgSettings;
        qrDevice.orgUuid = this.currentUser.orgSettings.orgUuid;

        const qrData = new QRModel();
        qrData.username = 'qr' + this.currentUser.orgSettings.uniqueOrgIdent;
        qrData.orgUuid = qrDevice.orgUuid;
        qrData.attachedUserId = this.currentUser._id;
        qrData.merchantId = this.currentUser.merchantId;
        qrData.accountType = this.currentUser.orgSettings.accountType;
        qrData.role = Constants.QR_ORDER;
        qrData.excludedCategories = this.excludedCategories;

        const takePayment = new TakePayment();
        takePayment.cloverConnectorPayment = false;
        takePayment.scanToPayPayment = false;
        takePayment.paymentRequired = this.paymentEnabled;
        takePayment.devicePaymentEnabled = this.paymentEnabled;
        takePayment.creditCardPayment = this.paymentEnabled;
        qrData.takePayment = takePayment;
        qrData.update = update;

        this.qrService.getOrCreateQrDevice(qrData, this.tablePassword).subscribe(response => {
                this.loading = false;
                this.tableUsername = response.username;
                this.siteAutoLoginURL = this.siteAutoLoginURL + 'email=' + this.tableUsername + '&password=' + this.tablePassword;
                this.paymentEnabled = response.takePayment?.paymentRequired;
                this.excludedCategories = response.excludedCategories;
                this.qrUrl =  `${environment.qrOrderURL}qr=`
                    + encodeURIComponent(this._AESEncryptDecryptService.encrypt(qrData.username));
                this.websiteUrl = 'https://' + this.currentUser.orgSettings.uniqueOrgIdent.substring(this.currentUser.orgSettings.uniqueOrgIdent.indexOf('@') + 1, this.currentUser.orgSettings.uniqueOrgIdent.indexOf('.com')) + `${environment.websiteSubdomainUrl}`; // this.qrUrl + '&website=true';

                if (closeDialog) {
                    this.popupType = 'qr';
                    // this.dialogRef.close();
                }
            },
            error => {
                throw new Error(error);
                this.loading = false;
            });
}
    getSelectedCategoriesList(currentCategoriesList: any, optionsList: any) {
        const selectedOptions = [];
        currentCategoriesList?.map((name) => {
            const x = optionsList.filter((option: any, ind: number) => option.name === name);
            selectedOptions.push(...x);
        });
        return selectedOptions;
    }


    onExcludeCategorySelect(item: any) {
        const selectedItem = this.cloverCategories.filter((value) => value.name === item.name)[0];
        if (!this.excludedCategories.includes(selectedItem.name)) {
            this.excludedCategories.push(selectedItem.name.toString());
        }
    }

    onExcludeCategoryDeSelect(item: any) {
        const exists = this.excludedCategories.includes(item.name);
        if (exists) {
            this.excludedCategories = this.excludedCategories.filter((name: string) => name !== item.name);
        }
    }

    submit() {
        this.dialogRef.close();
    }

    goToLink(url: string) {
        window.open(url, '_blank');
    }

    // get getItems() {
    getItems() {
        // return this.dropdownList.reduce((acc, curr) => {
        //     acc[curr.item_id] = curr;
        //     return acc;
        // }, {});
        // TODO: see what code above does
     }
    saveAsImage(parent) {
        let parentElement = null;

            // fetches base 64 data from canvas
            parentElement = parent.qrcElement.nativeElement
                .querySelector('canvas')
                .toDataURL('image/png');

        if (parentElement) {
            // converts base 64 encoded image to blobData
            const blobData = this.convertBase64ToBlob(parentElement);
            // saves as image
            const blob = new Blob([blobData], { type: 'image/png' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            // name of the file
            link.download = 'DineNGo QR Code';
            link.click();
        }
    }

    private convertBase64ToBlob(Base64Image: string) {
        // split into two parts
        const parts = Base64Image.split(';base64,');
        // hold the content type
        const imageType = parts[0].split(':')[1];
        // decode base64 string
        const decodedData = window.atob(parts[1]);
        // create unit8array of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // insert all character code into uint8array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // return blob image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }

    async getHeroImageURL() {
        const files = (<HTMLInputElement>document.getElementById('heroFile')).files[0];
        await this.getBase64(files).then((data) => {
            if (data) {
                this.orgSettingForm.controls['heroImage'].setValue(data.toString());
                this.heroImage = data.toString();
            }
        });
    }

    getSelectedDeliveryFeeName(): string {
        if (this.orgSettingForm.get('orderTypesEnabled').get('deliveryFee')?.value) {
            return this.orgSettingForm.get('orderTypesEnabled').get('deliveryFee').value?.name;
        }
    }
    get settingsForm() {
        return this.orgSettingForm.controls;
    }

    toggleDelivery(event: MatSlideToggleChange) {
        console.log('toggle', event.checked);
        if (event.checked) {
            this.openDeliveryFeeDialog('Select Delivery Fee',
                'If you dont see it, create an item in your inventory, refresh the page and try again.');
        } else {
            (this.settingsForm.orderTypesEnabled as FormArray).controls['deliveryFee'].reset();
        }
    }

    openDeliveryFeeDialog(message: string, title: string) {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            message: message,
            title: title,
        };

        const dialogRef = this.dialog.open(SelectDeliveryFeeItemPopupComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(data => {
            if (data?.selectedItem) {
                (this.settingsForm.orderTypesEnabled as FormArray).controls['deliveryFee'].setValue(data.selectedItem);
            } else {
                this.orgSettingForm.get('orderTypesEnabled').get('delivery').setValue(false);
            }
        });
    }

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = err => reject(err);
        });
    }

    async onSubmit() {
        this.submitted = true;

        // stop here if paymentForm is invalid
        if (this.orgSettingForm.invalid) {
            console.log('form invalid');
            return;
        }
        this.loading = true;
        const updatedSettings = new OrgSetting();
        Object.assign(updatedSettings, this.updateOrgSettings());
        this.orgService.updateWithoutWebhook(updatedSettings)
            .pipe(first())
            .subscribe((data: any) => {
                    this.alertService.success('Settings Updated', true);
                    this.orgSettingForm.reset(data);
                    this.currentUser.orgSettings = data;
                    this.utilsService.setCurrentUser(this.currentUser);
                    // data.newUser.token = this.currentUser.token;
                    // data.newUser.orgSettings = data.orgSettings;
                    // this.currentUser = data.newUser;
                    // localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(this.currentUser)));
                    // if (this.selectedRole === 'Admin') {
                    //     this.router.navigate(['/sidebar/admin/dashboard']);
                    // } else {
                    //     this.router.navigate(['/sidebar/admin/waiterMenu/serverOrder']);
                    // }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

    updateOrgSettings() {
        this.submitted = true;

        this.orgSettingForm.get('rewards')?.get('amountOff').setValue(this.orgSettingForm.get('rewards')?.get('amountOff').value * (-100));
        this.loading = true;

        const rewardsUpdate = new Reward();
        rewardsUpdate.rewardsEnabled = this.orgSettingForm.get('rewards')?.get('rewardsEnabled').value;
        rewardsUpdate.amountOff = this.orgSettingForm.get('rewards')?.get('amountOff').value;
        rewardsUpdate.afterVisits = this.orgSettingForm.get('rewards')?.get('afterVisits').value;

        const setting = new OrgSetting();
        setting.orgUuid = this.currentUser.orgSettings.orgUuid;
        setting.name = this.orgSettingForm.controls['name'].value;
        setting.email = this.orgSettingForm.controls['email'].value;
        setting.featuresEnabled = {
            hideGratuityOnTable: this.orgSettingForm.get('featuresEnabled').get('hideGratuityOnTable')?.value,
            jukeboxEnabled: this.orgSettingForm.get('featuresEnabled').get('jukeboxEnabled')?.value,
            marketingEnabled: this.orgSettingForm.get('featuresEnabled').get('marketingEnabled')?.value,
            reservationsEnabled: this.orgSettingForm.get('featuresEnabled').get('reservationsEnabled')?.value,
            serverOrderEdit: this.orgSettingForm.get('featuresEnabled').get('serverOrderEdit')?.value,
            serverTableMove: this.orgSettingForm.get('featuresEnabled').get('serverTableMove')?.value,
            v1Print: this.orgSettingForm.get('featuresEnabled').get('v1Print')?.value,
            addressEnabled: this.orgSettingForm.get('featuresEnabled').get('addressEnabled')?.value,
            websiteEnabled: this.orgSettingForm.get('featuresEnabled').get('websiteEnabled')?.value,
        };
        setting.orderTypesEnabled = {
            carryout: (this.settingsForm.orderTypesEnabled as FormArray).controls['carryout'].value,
            dineIn: (this.settingsForm.orderTypesEnabled as FormArray).controls['dineIn'].value,
            delivery: (this.settingsForm.orderTypesEnabled as FormArray).controls['delivery'].value,
            deliveryFee: (this.settingsForm.orderTypesEnabled as FormArray).controls['deliveryFee'].value,
            deliveryRadius: (this.settingsForm.orderTypesEnabled as FormArray).controls['deliveryRadius'].value
        };
        setting.address = {
            line1: (this.settingsForm.address as FormArray).controls['line1'].value,
            line2: (this.settingsForm.address as FormArray).controls['line2'].value,
            city: (this.settingsForm.address as FormArray).controls['city'].value,
            state: (this.settingsForm.address as FormArray).controls['state'].value,
            postal_code: (this.settingsForm.address as FormArray).controls['postal_code'].value,
            country: (this.settingsForm.address as FormArray).controls['country'].value,
            longitude: (this.settingsForm.address as FormArray).controls['longitude'].value,
            latitude: (this.settingsForm.address as FormArray).controls['latitude'].value
        };
        setting.businessHours = {
            monday: {
                openTime: (this.settingsForm.businessHours.get('monday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('monday') as FormArray).controls['closeTime'].value
            },
            tuesday: {
                openTime: (this.settingsForm.businessHours.get('tuesday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('tuesday') as FormArray).controls['closeTime'].value
            },
            wednesday: {
                openTime: (this.settingsForm.businessHours.get('wednesday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('wednesday') as FormArray).controls['closeTime'].value
            },
            thursday: {
                openTime: (this.settingsForm.businessHours.get('thursday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('thursday') as FormArray).controls['closeTime'].value
            },
            friday: {
                openTime: (this.settingsForm.businessHours.get('friday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('friday') as FormArray).controls['closeTime'].value
            },
            saturday: {
                openTime: (this.settingsForm.businessHours.get('saturday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('saturday') as FormArray).controls['closeTime'].value
            },
            sunday: {
                openTime: (this.settingsForm.businessHours.get('sunday') as FormArray).controls['openTime'].value,
                closeTime: (this.settingsForm.businessHours.get('sunday') as FormArray).controls['closeTime'].value
            },
        };
        setting.phoneNumber = this.orgSettingForm.controls['phoneNumber'].value;
        setting.customerReviewUrl = this.orgSettingForm.controls['customerReviewUrl'].value;
        setting.rewards = rewardsUpdate;
        // setting.image = this.profileImage; TODO: uncomment and setup
        setting.heroImage = this.heroImage;
        return setting;
    }
}

