<div class="qrcodeImage" *ngIf="qrUrl">
  <qrcode
    [qrdata]="qrUrl"
    [allowEmptyString]="true"
    [attr.ariaLabel]="'QR Code image with the following content...'"
    [cssClass]="'center'"
    [colorDark]="colorDark"
    [colorLight]="colorLight"
    [elementType]="'canvas'"
    [errorCorrectionLevel]="'M'"
    [attr.imageSrc]="imageSrc ? imageSrc : './assets/default-logo.png'"
    [attr.imageHeight]="height"
    [attr.imageWidth]="width"
    [margin]="4"
    [scale]="1"
    [title]="title"
    [width]="width"
  ></qrcode>
</div>
