import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {User} from '../_models';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import {Constants} from '../constants/constants';
import {UtilsService} from './utils.service';

@Injectable()
export class AuthenticationService {
    private readonly loggedInSubject: Subject<boolean>;
    role: String;
    private readonly _authenticatedUser: Subject<User>;


    constructor(private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService, private router: Router,
                private utilsService: UtilsService) {
        this.loggedInSubject = new Subject();
        this._authenticatedUser = new Subject<User>();
    }

    login(username: string, password: string) {
        // console.log('In AuthenticateService login'); //*MES*
        return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, {username: username, password: password})
            .pipe(map((user: any) => {
                // console.log(`${environment.apiUrl}`);
                // login successful if there's a jwt token in the response
                if (user?.user) {
                    this.role = user.user.role[0];
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.utilsService.setCurrentUser(user.user, this.role);
                    this._authenticatedUser.next(user.user);
                    this.loggedInSubject.next(true);
                } else if (user?.redirectURL) {
                    return user;
                } else {
                    throw new Error('Invalid Username or Password');
                    return;
                }
                console.log('AuthenticateService login completed'); // *MES*
                return user;
            }));
    }
    cloverLogin(merchantId: string, client_id: string, code: string) {
        let currentUser;
        if (localStorage.getItem('currentUser')) {
            currentUser = this.utilsService.getCurrentUser();
        }
        if (currentUser?.orgUuid) {
            return this.http.post<any>(`${environment.apiUrl}/cloverApi/authenticate`, {
                merchantId: merchantId,
                client_id: client_id,
                code: code
            }, {
                headers: new HttpHeaders().set('Authorization', currentUser?.orgUuid),
            }).pipe(map(user => {
                    if (!currentUser?.orgSettings && user) {
                        this.utilsService.setCurrentUser(user);
                        this._authenticatedUser.next(user);
                        this.loggedInSubject.next(true);
                    }
                    return user;
                }));
        } else {
            return this.http.post<any>(`${environment.apiUrl}/cloverApi/authenticate`, {
                merchantId: merchantId,
                client_id: client_id,
                code: code
            }).pipe(map(user => {
                    if (!currentUser?.orgSettings && user) {
                        this.utilsService.setCurrentUser(user);
                        this._authenticatedUser.next(user);
                        this.loggedInSubject.next(true);
                    }
                    return user;
                }));
        }
    }

    // cloverLogin(merchantId: string, client_id: string, code: string) {
    //     const currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.
    //         Item('currentUser')));
    //
    //     return this.http.post<any>(`${environment.apiUrl}/cloverApi/authenticate`, {
    //         merchantId: merchantId,
    //         client_id: client_id,
    //         code: code
    //     }, {
    //         headers: new HttpHeaders().set('Authorization', currentUser.orgUuid),
    //     })
    //         .pipe(map(user => {
    //             localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(user)));
    //             localStorage.setItem('selectedRole', this._AESEncryptDecryptService.encrypt(user.role[0]));
    //             this._authenticatedUser.next(user);
    //             this.loggedInSubject.next(true);
    //             return user;
    //         }));
    // }


    squareLogin(code: string) {
        if (localStorage.getItem(Constants.CURRENT_USER)) {
            const user: User = this.utilsService.getCurrentUser();
        }
        return this.http.post<any>(`${environment.apiUrl}/squareApi/authenticate`, {code: code})
            .pipe(map(user => {
                if (user) {
                    this.utilsService.setCurrentUser(user);
                    this._authenticatedUser.next(user);
                    this.loggedInSubject.next(true);
                }
                return user;
            }));
    }


    generateOTC(username) {
        return this.http.post<any>(`${environment.apiUrl}/users/generateOTP`, {username});
    }

    verifyOTC(otp, username) {
        return this.http.post<any>(`${environment.apiUrl}/users/verifyOTP`, {otp, username});
    }

    resetPassword(otp, password, userId) {
        return this.http.post<any>(`${environment.apiUrl}/users/resetPassword`, {otp, password, userId});
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        sessionStorage.clear();
        this.loggedInSubject.next(false);
        // console.log('in AuthenticateService logout'); //*MES*
        this.router.navigate(['/login']);
    }

    get user() {
        return this._authenticatedUser;
    }

    setUser(user) {
        this._authenticatedUser.next(user);
        this.loggedInSubject.next(true);
    }

    getLoggedInSubject() {
        // console.log('in AuthenticateService getLoggedInSubject'); //*MES*
        return this.loggedInSubject;
    }
}
