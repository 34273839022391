<div class="container">
    <h2 mat-dialog-title>{{description}}</h2>
    <mat-dialog-content>
        <div class="qrcodeImage">
            <qrcode
                    [qrdata]="qrUrl"
                    [allowEmptyString]="true"
                    [attr.ariaLabel]="'QR Code image with the following content...'"
                    [cssClass]="'center'"
                    [colorDark]="colorDark"
                    [colorLight]="colorLight"
                    [elementType]="'canvas'"
                    [errorCorrectionLevel]="'M'"
                    [attr.imageSrc]="imageSrc ? imageSrc : './assets/default-logo.png'"
                    [attr.imageHeight]="height"
                    [attr.imageWidth]="width"
                    [margin]="4"
                    [scale]="1"
                    [title]="title"
                    [width]="width"
            ></qrcode>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="button-container">
            <button mdbBtn color="info" block="true" class="my-4"
                    (click)="close()">{{ 'button.finish' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
