<div class="d-flex justify-content-center p-2">
    <h1 class="font-weight-bold">Website & QR-Code</h1>
</div>
<mat-dialog-content class="popover-container">
    <div class="dialog-options-container">
        <mat-button-toggle-group [(ngModel)]="popupType" aria-label="Font Style">
            <mat-button-toggle value="website">Website Ordering</mat-button-toggle>
            <mat-button-toggle value="qr">QR Code</mat-button-toggle>
            <mat-button-toggle value="settings">Settings</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="error-message">{{errorMessage}}</div>
    </div>
    <div class="text-container" *ngIf="popupType === 'settings'">
        <ng-container *ngTemplateOutlet="settings"></ng-container>
    </div>
    <div class="text-container-qr" *ngIf="popupType === 'qr'">
        <ng-container *ngTemplateOutlet="qrScanner"></ng-container>
    </div>
    <div class="text-container-qr" *ngIf="popupType === 'website'">
        <ng-container *ngTemplateOutlet="websiteOrdering"></ng-container>
    </div>
</mat-dialog-content>

<ng-template #websiteOrdering>
    <div class="card p-3">
        <div class="card-title">Website</div>
        <div class="general-padding font-weight-bold display-center-flex"><label><b style="font-size: 12px;">Website
            URL:</b></label>
            <input class="autologin-url-input" type="text" [value]="websiteUrl" readonly/></div>
        <br>
        <p class="website-instructions card-text"><span class="instructions-header">Instructions: </span>To add ordering
            through your
            website, add the link to the order button on your site. Upon clicking the link below, customers will be
            routed to
            the ordering screen. </p>
        <!--        <div class="creds-container">-->
        <!--            <a href="#" (click)="goToLink(websiteUrl)" class="link">Website URL</a>-->
        <!--        </div>-->
    </div>
</ng-template>

<ng-template #qrScanner>
    <div class="card p-3">
        <div class="card-title">QR Code</div>
        <qrcode #qrcode [qrdata]="qrUrl" [allowEmptyString]="true"
                [attr.ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
                [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'" [elementType]="'canvas'" [errorCorrectionLevel]="'L'"
                [attr.imageSrc]="currentUser.orgSettings.image ? currentUser.orgSettings.image : './assets/default-logo.png'"
                [attr.imageHeight]="75" [attr.imageWidth]="75" [margin]="4" [scale]="1" [title]="'Scan To Order'"
                [width]="250">
        </qrcode>
        <div class="download-button-container">
            <button mdbBtn [disabled]="loading" *ngIf="popupType === 'qr'" block="true" class="download-button"
                    (click)="saveAsImage(qrcode)">Download QR
            </button>
        </div>
        <div class="hr">
            <span> OR </span>
        </div>
        <br><br>
        <h1 class="card-text">Show QR Code On A Device</h1>
        <div class="creds-container">
            <div class="d-flex justify-content-center">
                <a href="#" class="link pr-2" (click)="goToLink(siteURL)">URL</a>
                <a href="#" class="link pr-2" (click)="goToLink(siteAutoLoginURL)">Auto login URL</a>

            </div>
            <div class="general-padding">Username: <span class="creds">{{tableUsername}}</span></div>
            <div class="general-padding">One Time Password: <span class="creds"> &nbsp; {{tablePassword}} &nbsp; &nbsp; <b
                    style="font-weight: bold;">OR</b> &nbsp; &nbsp; Admin's Password</span>
                &nbsp; &nbsp;
            </div>
        </div>
    </div>
</ng-template>

<ng-template #settings>
    <div class="card p-3">
        <div class="card-title d-flex justify-content-center">Settings</div>
            <div class="toggle-selector">
                <ng-multiselect-dropdown [placeholder]="'Excluded Categories'" [settings]="excludedDropdownSettings"
                                         [data]="cloverCategories" [ngModelOptions]="{standalone: true}"
                                         [(ngModel)]='selectedCategories'
                                         (onDeSelect)="onExcludeCategoryDeSelect($event)"
                                         (onSelect)="onExcludeCategorySelect($event)" multiple>
                    <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                    </ng-template>
                </ng-multiselect-dropdown>
            </div>
            <div class="form-container" *ngIf="selectedRole === Constants.ADMIN || selectedRole === Constants.MANAGER">
                <form [formGroup]="orgSettingForm">
                    <!--                    <div class="card-text pt-2 pb-2 d-flex justify-content-center">-->
                    <!--                        <mat-slide-toggle [ngModelOptions]="{standalone: true}" [(ngModel)]="paymentEnabled">Payment-->
                    <!--                            Enabled:-->
                    <!--                            {{paymentEnabled}}</mat-slide-toggle>-->
                    <!--                    </div> &nbsp;&nbsp;-->

                    <div class="d-flex justify-content-end pb-3">
                      <span formGroupName="orderTypesEnabled">
                        <mat-slide-toggle id="deliveryToggle" #deliveryToggle class="paymentToggle" formControlName="delivery"
                                          (change)="toggleDelivery($event)"
                                          *ngIf="currentUser">Delivery Enabled</mat-slide-toggle>
                      </span>
                        &nbsp;&nbsp;
                        <span formGroupName="featuresEnabled"
                              *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">
                          <mat-slide-toggle class="paymentToggle" formControlName="websiteEnabled" *ngIf="currentUser">Website Enabled</mat-slide-toggle>
                        </span> &nbsp;&nbsp;
                        <span formGroupName="featuresEnabled" *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER">
                            <mat-slide-toggle class="paymentToggle" formControlName="addressEnabled" *ngIf="currentUser">
                                Enable Address</mat-slide-toggle>
                        </span>
                    </div>
                    <div>
<!--                        <mat-form-field>-->
<!--                            <mat-label>Every 3.5 hours</mat-label>-->
<!--                            <input matInput [matTimepicker]="hoursPicker">-->
<!--                            <mat-timepicker-toggle matIconSuffix [for]="hoursPicker"/>-->
<!--                            <mat-timepicker interval="3.5h" #hoursPicker/>-->
<!--                        </mat-form-field>-->
                    </div>
                    <div formGroupName="businessHours" *ngIf="orgSettingForm.get('featuresEnabled').get('websiteEnabled').value === true">
                        <div class="store-hours-label pb-3">Store Hours:&nbsp;&nbsp;
                        </div>
                        <div formGroupName="monday" class="d-flex timer">
                            <div class="day-label">
                                <p class="card-text">Monday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="mondayopenTime" readonly>
                                <ngx-material-timepicker #mondayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="mondaycloseTime" readonly>
                                <ngx-material-timepicker #mondaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>
                        <div formGroupName="tuesday" class="d-flex">
                            <div class="day-label">
                                <p class="card-text">Tuesday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="tuesdayopenTime" readonly>
                                <ngx-material-timepicker #tuesdayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="tuesdaycloseTime" readonly>
                                <ngx-material-timepicker #tuesdaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>

                        <div formGroupName="wednesday" class="d-flex">
                            <div class="day-label">
                                <p class="card-text">Wednesday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="wednesdayopenTime" readonly>
                                <ngx-material-timepicker #wednesdayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="wednesdaycloseTime" readonly>
                                <ngx-material-timepicker #wednesdaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>

                        <div formGroupName="thursday" class="d-flex">
                            <div class="day-label">
                                <p class="card-text">Thursday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="thursdayopenTime" readonly>
                                <ngx-material-timepicker #thursdayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="thursdaycloseTime" readonly>
                                <ngx-material-timepicker #thursdaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>

                        <div formGroupName="friday" class="d-flex">
                            <div class="day-label">
                                <p class="card-text">Friday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="fridayopenTime" readonly>
                                <ngx-material-timepicker #fridayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="fridaycloseTime" readonly>
                                <ngx-material-timepicker #fridaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>

                        <div formGroupName="saturday" class="d-flex">
                            <div class="day-label">
                                <p class="card-text">Saturday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="saturdayopenTime" readonly>
                                <ngx-material-timepicker #saturdayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="saturdaycloseTime" readonly>
                                <ngx-material-timepicker #saturdaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>

                        <div formGroupName="sunday" class="d-flex">
                            <div class="day-label">
                                <p class="card-text">Sunday</p>
                            </div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="openTime"
                                       [ngxTimepicker]="sundayopenTime" readonly>
                                <ngx-material-timepicker #sundayopenTime></ngx-material-timepicker>
                            </mat-form-field>
                            <div class="day-label">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                            <mat-form-field class="default-time-example" appearance="outline">
                                <input matInput aria-label="default time" [format]="24" formControlName="closeTime"
                                       [ngxTimepicker]="sundaycloseTime" readonly>
                                <ngx-material-timepicker #sundaycloseTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>

                        <mat-divider></mat-divider>
                        <br><br>
                        <div>
                            <div class="store-hours-label pb-3">Hero Banner:&nbsp;&nbsp;
                            </div>

                            <div class="logo-image-container">
                                <img (click)="imageUpdated = true;selectHeroImage.click()" class="banner-image-logo"
                                     [src]="currentUser.orgSettings.heroImage ? currentUser.orgSettings.heroImage : './assets/noImageAvailable.png'">
                            </div>
                            <div class="upload-btn-wrapper">
                                <input #selectHeroImage type="file" name="heroFile" id="heroFile" accept="image/*"
                                       (change)="getHeroImageURL()"/>
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <br>
                    <div class="business-address-container"
                         *ngIf="orgSettingForm.get('orderTypesEnabled').get('delivery').value === true">
                        <div class="rewards-text" style="font-weight: bold">Delivery: &nbsp;&nbsp;</div>
                        <mat-form-field class="store-hours-label" appearance="outline">&nbsp;&nbsp;
                            <mat-label>Address:</mat-label>
                            <input matInput id="autocomplete" ngx-google-places-autocomplete placeholder="Address"
                                   [value]="stringAddress"
                                   (onAddressChange)="AddressChange($event)"/>
                        </mat-form-field> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <mat-form-field class="store-hours-label" formGroupName="orderTypesEnabled"
                                        appearance="outline">
                            &nbsp;&nbsp;
                            <mat-label>Radius:</mat-label>
                            <input matInput type="number" placeholder="Radius (Miles)"
                                   formControlName="deliveryRadius"/>
                        </mat-form-field>
                    </div><br>
                    <div *ngIf="orgSettingForm.get('orderTypesEnabled').get('delivery').value === true" class="business-address-container">&nbsp;&nbsp;
                        <mat-label class="rewards-text" style="font-weight: bold">Fee:</mat-label>
                        <div class="rewards-text"
                             *ngIf="getSelectedDeliveryFeeName()">
                            &nbsp;&nbsp;{{getSelectedDeliveryFeeName()}}</div>
                    </div>
                </form>
                <button [disabled]="!(orgSettingForm.dirty || settingsForm.dirty || imageUpdated)" mdbBtn
                        color="info" block="true" id="registerUser" (click)="onSubmit()" class="my-4">Update
                </button>
            </div>
            <!--      <div class="card-text p-3"><span style="font-weight: bold;">Note</span>-->
            <!--        <div class="card-text pt-2">-->
            <!--          1. To Enable and Disable Rewards, Change it under-->
            <!--          Organization Settings by tapping the settings icon in the sidebar menu.-->
            <!--        </div>-->
            <!--        <div class="card-text pt-2">-->
            <!--          2. Payment is enabled by default for website login.-->
            <!--        </div>-->
            <!--      </div>-->
    </div>
</ng-template>
