

<div class="login-code-container">
  <h2>Activate Your Device</h2>
  <p></p>
  
  <p *ngIf="isCodeVisible">
    <strong>Your Activation Code:</strong> {{ generatedCode }}
  </p>
  
  <input type="text" placeholder="Enter Activation Code" [(ngModel)]="activationCode" maxlength="6">
  <button (click)="onActivate()">Activate</button>
</div>
