import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PaymentPayload} from '../_models/paymentPayload';

@Injectable({
  providedIn: 'root'
})
export class SquarePayServiceService {

  constructor(private http: HttpClient) {
  }

  processPayment(paymentPayload: PaymentPayload) {

    // const localMerchantId = localStorage.getItem('merchant_id');
    // paymentPayload.merchant_id = localMerchantId;

    return this.http.post<any>(`${environment.apiUrl}/squarePay/getAll`, paymentPayload);
  }

  createPaymentLink(paymentPayload: PaymentPayload) {
    return this.http.post<any>(`${environment.apiUrl}/squarePay/createPaymentLink`, paymentPayload);
  }

  terminalPayRequest(paymentPayload) {
    return this.http.post<any>(`${environment.apiUrl}/squarePay/terminalPayRequest`, paymentPayload);
  }

  deletePaymentLink(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}/squarePay/deletePaymentLink/` + id);
  }

  processPaymentV2(paymentPayload: PaymentPayload) {

    // const localMerchantId = localStorage.getItem('merchant_id');
    // paymentPayload.merchant_id = localMerchantId;

    return this.http.post<any>(`${environment.apiUrl}/squarePay/processPayment`, paymentPayload);
  }
}
