import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AlertComponent} from './directives';
import {AlertService} from '../_services';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {TimeAgoPipe} from 'time-ago-pipe';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {NgApexchartsModule} from 'ng-apexcharts';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CartListComponent} from './components/cart-list/cart-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatChipsModule} from '@angular/material/chips';
import {AutosizeModule} from 'ngx-autosize';
import {GuidedTourModule} from 'ngx-guided-tour';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSortModule} from '@angular/material/sort';
import {RouterModule} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DropdownUserRolesComponent} from './components/dropdown-user-roles/dropdown-user-roles.component';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
    CustomMessageItemDialogComponent
} from './components/custom-message-item-dialog/custom-message-item-dialog.component';
import {
    NotificationConfirmationComponent
} from './components/notification-confirmation-component/notification-confirmation.component';
import {
    ItemOptionsDialogComponentComponent
} from './components/item-options-dialog-component/item-options-dialog-component.component';
import {NotificationPopupComponent} from './components/notification-popup/notification-popup.component';
import {UploadImageDialogComponent} from './components/upload-image-dialog/upload-image-dialog.component';
import {
    DropdownLanguageSelectorComponent
} from './components/dropdown-language-selector/dropdown-language-selector.component';
import {
    DropdownBooleanSelectorComponent
} from './components/dropdown-boolean-selector/dropdown-boolean-selector.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {LoginModule} from '../login/login.module';
import {LoginComponent} from '../login/login';
import {LogoutComponent} from '../login/logout/logout.component';
import {RegisterComponent} from '../login/register';
import {SwiperModule} from 'swiper/angular';
import {RatingsComponent} from './components/ratings-conponent/ratings.component';
import {QrGeneratorComponent} from './components/qr-generator/qr-generator.component';
import {QRCodeModule} from 'angularx-qrcode';
import {OptionsSlideSelectorComponent} from './components/options-slide-selector/options-slide-selector.component';
import {SWIPER_CONFIG, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {RoleSelectComponent} from '../login/role-select/role-select.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {QrPayPopupComponent} from './components/qr-pay-popup/qr-pay-popup.component';
import {
    NotificationAndImagePopupComponent
} from './components/notification-and-image-popup/notification-and-image-popup.component';
import {
    NotificationPaymentRequiredQrPopupComponent
} from './components/notification-payment-required-qr-popup/notification-payment-required-qr-popup.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MoveTablePopupComponent} from './components/move-table-popup/move-table-popup.component';
import {CustomInputPopupComponent} from './components/custom-input-popup/custom-input-popup.component';
import {CustomDiscountInputPopupComponent} from './components/custom-discount-input-popup/custom-discount-input-popup.component';
import { ConnectLoginTableComponent } from './components/connect-login-Table-popup/connect-login-table.component';
import {
    NotificationPaymentRequiredPopupComponent
} from './components/square-notification-payment-required-popup/notification-payment-required-popup.component';
import { ItemOptionsDialogComponentSquare } from './item-options-dialog-component-square/item-options-dialog-component-square.component';
import {SquarePaymentPopupComponent} from './components/square-payment-popup/square-payment-popup.component';
import {CartListSquareComponent} from './components/cart-list-square/cart-list-square.component';
import { SquareDevicePairingPopupComponent } from './square-device-pairing-popup/square-device-pairing-popup.component';
import {FormatTime} from './directives/format-time.pipes';
import {QRCodeLoginPopupComponent} from './components/QR-login-popup/QR-code-login-popup.component';
import {TextPromotionPopupComponent} from './components/text-promotion-popup/text-promotion-popup.component';
import {JukeboxLoginPopupComponent} from './components/jukebox-login-popup/jukebox-login-popup.component';
import {CategoriesSlideSelectorComponent} from './components/cateogries-slide-selector-vertical/categories-slide-selector.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {SelectDeliveryFeeItemPopupComponent} from './components/select-delivery-fee-item-popup/select-delivery-fee-item-popup.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { RestaurentTimingsComponent } from './components/restaurent-timings/restaurent-timings.component';
import {NewDeviceLoginComponent} from './components/new-device-login/new-device-login.component';
import { OtpDialogComponent } from './components/otp-dialog/otp-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};
const materialModules = [
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule
];

@NgModule({
    declarations: [AlertComponent, TimeAgoPipe, CartListComponent, CartListSquareComponent, DropdownUserRolesComponent, CustomMessageItemDialogComponent,
        NotificationConfirmationComponent, NotificationPopupComponent, ItemOptionsDialogComponentComponent, UploadImageDialogComponent,
        DropdownLanguageSelectorComponent, FormatTime, DropdownBooleanSelectorComponent, NotificationAndImagePopupComponent, LoginComponent,
        RoleSelectComponent, QrPayPopupComponent, LogoutComponent, CustomInputPopupComponent, SquarePaymentPopupComponent,
        CustomDiscountInputPopupComponent, ItemOptionsDialogComponentSquare, RegisterComponent, RatingsComponent, QrGeneratorComponent,
        OptionsSlideSelectorComponent, CategoriesSlideSelectorComponent, SelectDeliveryFeeItemPopupComponent, NotificationPaymentRequiredQrPopupComponent, SquareDevicePairingPopupComponent,
        NotificationPaymentRequiredPopupComponent, MoveTablePopupComponent, QRCodeLoginPopupComponent, ConnectLoginTableComponent,
        TextPromotionPopupComponent, JukeboxLoginPopupComponent, RestaurentTimingsComponent, NewDeviceLoginComponent, OtpDialogComponent],
    imports: [
        CommonModule,
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MDBBootstrapModule.forRoot(),
        MatFormFieldModule,
        FlexLayoutModule,
        MatDialogModule,
        SwiperModule,
        MatSidenavModule,
        MatTooltipModule,
        MatTabsModule,
        MatListModule,
        MatButtonToggleModule,
        MatTableModule,
        NgApexchartsModule,
        MatProgressSpinnerModule,
        DragDropModule,
        MatChipsModule,
        AutosizeModule,
        HttpClientModule,
        InfiniteScrollModule,
        GuidedTourModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSortModule,
        LoginModule,
        HttpClientModule,
        RouterModule,
        MatDividerModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatExpansionModule,
        NgbModule,
        QRCodeModule,
        GooglePlaceModule,
        MatProgressBarModule,
        VirtualScrollerModule,
        ...materialModules
    ],
    entryComponents: [
        ItemOptionsDialogComponentComponent,
        ItemOptionsDialogComponentSquare,
        NotificationPopupComponent,
        SelectDeliveryFeeItemPopupComponent,
        SquarePaymentPopupComponent,
        NotificationPaymentRequiredQrPopupComponent,
        NotificationPaymentRequiredPopupComponent,
        NotificationAndImagePopupComponent,
        MoveTablePopupComponent,
        ConnectLoginTableComponent,
        NewDeviceLoginComponent,
        SquareDevicePairingPopupComponent,
        QrPayPopupComponent,
        CustomInputPopupComponent,
        CustomDiscountInputPopupComponent,
        TextPromotionPopupComponent,
        NotificationConfirmationComponent,
        UploadImageDialogComponent,
        QRCodeLoginPopupComponent,
        JukeboxLoginPopupComponent,
        OtpDialogComponent],
    exports: [
        TranslateModule,
        NgMultiSelectDropDownModule,
        MDBBootstrapModule,
        AlertComponent,
        MatCheckboxModule,
        MatDialogModule,
        MatSortModule,
        HttpClientModule,
        RouterModule,
        MatDividerModule,
        MatPaginatorModule,
        GooglePlaceModule,
        MatSlideToggleModule,
        MatTabsModule,
        NgbModule,
        MatListModule,
        FlexLayoutModule,
        MatButtonToggleModule,
        MatExpansionModule,
        InfiniteScrollModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatTableModule,
        DragDropModule,
        SwiperModule,
        LoginModule,
        MatDatepickerModule,
        TimeAgoPipe,
        MatTooltipModule,
        MatSidenavModule,
        NgApexchartsModule,
        CartListComponent,
        CartListSquareComponent,
        MatChipsModule,
        AutosizeModule,
        HttpClientModule,
        GuidedTourModule,
        DropdownUserRolesComponent,
        NotificationConfirmationComponent,
        NotificationAndImagePopupComponent,
        NotificationPaymentRequiredQrPopupComponent,
        NotificationPaymentRequiredPopupComponent,
        SquareDevicePairingPopupComponent,
        NotificationPopupComponent,
        QrPayPopupComponent,
        CustomInputPopupComponent,
        CustomDiscountInputPopupComponent,
        TextPromotionPopupComponent,
        CustomMessageItemDialogComponent,
        DropdownLanguageSelectorComponent,
        LoginComponent,
        LogoutComponent,
        RegisterComponent,
        ItemOptionsDialogComponentComponent,
        ItemOptionsDialogComponentSquare,
        SquarePaymentPopupComponent,
        DropdownBooleanSelectorComponent,
        RatingsComponent,
        QrGeneratorComponent,
        OptionsSlideSelectorComponent,
        FormatTime,
        CategoriesSlideSelectorComponent,
        VirtualScrollerModule,
        ConnectLoginTableComponent,
        NewDeviceLoginComponent,
        JukeboxLoginPopupComponent,
        QRCodeLoginPopupComponent,
        RestaurentTimingsComponent,
        OtpDialogComponent,
        ...materialModules
    ],
    providers: [
        AlertService,
        TranslateService,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        {provide: MatDialogRef, useValue: {}},
        {provide: MAT_DIALOG_DATA, useValue: []},
    ]
})
export class SharedModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
}
