import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from '../constants/constants';
import {User} from '../_models';
import {AESEncryptDecryptService} from '../_services/aesencrypt-decrypt.service';
import {UtilsService} from '../_services/utils.service';

@Injectable()
export class AuthGuard implements CanActivate {

  currentUser: User;
  qrDevice: User;
  constructor(private router: Router, private _AESEncryptDecryptService: AESEncryptDecryptService,
              private utilsService: UtilsService) {
    if (localStorage.getItem(Constants.CURRENT_USER)) {
      this.currentUser = this.utilsService.getCurrentUser();
    } else if (sessionStorage.getItem(Constants.CURRENT_USER)) {
      this.qrDevice = this.utilsService.getCurrentUser();
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
   // console.log("isAuthenticated",isAuthenticated);
    //console.log("user",this.currentUser);
    if (this.currentUser) {
      return true;
    } else if (this.qrDevice) {
      return true;
    } else if (isAuthenticated) {
      return true;
    }
    else {
      this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
      return false;
    }
  }
}
