import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService } from '../../_services/index';
import { User } from '../../_models/index';
import { Image } from '../../_models/image';
import { AESEncryptDecryptService } from '../../_services/aesencrypt-decrypt.service';
import {environment} from '../../../environments/environment';
import {Category} from '../../_models/category';
import { Constants } from 'src/app/constants/constants';
import { Preferences } from '@capacitor/preferences';
import {Location} from '@angular/common';
import {UtilsService} from '../../_services/utils.service';

@Component({
  selector: 'app-login-page',
  styleUrls: ['login.component.scss'],
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  Constants = Constants;
  loginForm: FormGroup;
  loading = false;
  passwordResetEmailMissing = false;
  submitted = false;
  returnUrl: string;
  error = false;
  currentUser: User;
  images: Image[] = [];
  tableUuid: string;
  tableName: string;
  localMerchantId: any;
  errorMessage: any;
  storedMerchantId: string;
  storedCloverToken: string;
  categoriesFirstMerchants: string[];
  categoriesFirstSwitch: boolean;
  cloverCategories: Category[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private utilsService: UtilsService,
    private alertService: AlertService) {

    this.currentUser = utilsService.getCurrentUser();

    this.categoriesFirstMerchants = ['invalid id'];
    this.categoriesFirstSwitch = true;

  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.performUrlParamsInit();
  }

  private performUrlParamsInit() {
    const url_string = window.location.href;
    const url = new URL(url_string);

    if (url.searchParams.get('table')) {
      localStorage.setItem('table', url.searchParams.get('table'));
    }
    if ((url.searchParams.get('email') || url.searchParams.get('username')) && url.searchParams.get('password')) {
      const username = url.searchParams.get('email').toLowerCase() ? url.searchParams.get('email').toLowerCase() : url.searchParams.get('username').toLowerCase();
      this.loginForm.controls.username.setValue(username);
      this.loginForm.controls.password.setValue(url.searchParams.get('password'));
      this.onSubmit(url);
    }
    if (Boolean(url.searchParams.get('mobile')) === true && this.currentUser?.role) {
      this.capacitorStoageSet().then(r => {
        if (this.currentUser.role.length > 1) {
          this.router.navigate(['/select-role']);
        } else {
          this.routeToProperComponent(this.currentUser.role[0]);
        }
      });
      if (this.currentUser.role.length > 1) {
        this.router.navigate(['/select-role']);
      } else {
        this.routeToProperComponent(this.currentUser.role[0]);
      }
      return;
    } else {

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    this.deleteQueryParameterFromCurrentRoute();
  }

// convenience getter for easy access to paymentForm fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit(url?) {
    this.authenticationService.logout();
    localStorage.clear();
    sessionStorage.clear();
    const table = localStorage.getItem('table');
    if (url && url.searchParams.get('table')?.toLowerCase()) {
      localStorage.setItem('table', url.searchParams.get('table').toLowerCase());
    }
    if (table) {
      localStorage.setItem('table', table);
    }
    this.error = false;
    this.submitted = true;
    // stop here if paymentForm is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value.toLowerCase(), this.f.password.value)
      .subscribe(
        async (data: any) => {
          await this.setCapacitorUser(this._AESEncryptDecryptService.encrypt(JSON.stringify(data.user)));

          if (data.redirectURL) {
            const url = new URL(data.redirectURL);
            if (!(data.user && data.user.orgSettings?.accountType === Constants.CLOVER) && url.searchParams.get('user')) {
              url.searchParams.set('user', this._AESEncryptDecryptService.encrypt(url.searchParams.get('user')));
              data.redirectURL = url;
            }
            this.utilsService.setCurrentUser(data.user, data.user.role[0]);
            window.location.assign(data.redirectURL);
          } else if (!data.user && data.orgSettings?.accountType === Constants.SQUARE) {
            this.routeToProperSquareComponent(data.role);
          } else {
            if (data.user?.role.length > 1) {
              this.router.navigate(['/select-role']);
            } else {
              this.utilsService.setSelectedRole(data.user.role[0]);
              localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
              this.routeToProperComponent(data.user.role[0]);
            }
          }
          await this.setCapacitorUser(this._AESEncryptDecryptService.encrypt(JSON.stringify(data.user)));

        },
        error => {
          this.error = true;
          this.alertService.error(error);
          this.loading = false;
        });

    console.log('in login.component.ts OnSubmit completed1'); // *MES* - doesnt reach here mostly.
  }

  private routeToProperComponent(role) {
    if ((role === Constants.ADMIN)) {
      this.router.navigate(['sidebar/admin/dashboard'])
        .then(() => {
          // window.location.reload();
        });
    } else if (role === Constants.MANAGER || role === Constants.SERVER) {
      this.router.navigate(['sidebar/admin/waiterMenu/serverOrder']).then(() => {
        // window.location.reload();
      });
    } else if (role === Constants.TABLE) {
      this.router.navigate(['/tableSetup'])
        .then(() => {
          // window.location.reload();
        });
    } else if (role === 'Rewards') {
      this.router.navigate(['/rewards'])
        .then(() => {
          // window.location.reload();
        });
    } else if (role === Constants.KIOSK) {
      this.router.navigate(['/kiosk']).then(() => {
        // window.location.reload();

      });
    } else if (role === Constants.JUKEBOX) {
      this.router.navigate(['/youtube']).then(() => {
        // window.location.reload();

      });
    } else if (role === Constants.QR_ORDER) {
      this.router.navigate(['/QROrder']).then(() => {
        // window.location.reload();

      });
    } else if (role === 'PaymentsDisplay') {
      this.router.navigate(['/paymentUser/paymentUser']).then(() => {
        // window.location.reload();
      });
    } else if (role === Constants.ORDERS_DISPLAY) {
      this.router.navigate(['/orderUser/orderUser']).then(() => {
        // window.location.reload();
      });

    } else {
      this.router.navigate(['/login'], { queryParamsHandling: 'preserve' }).then(() => {
      });
    }
  }

  getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
      if (c.indexOf(nameEQ) === 0) {
        return true;
      }
    }
    return false;
  }

  private routeToProperSquareComponent(role) {
    if ((role === 'Admin')) {
      window.location.href = `${environment.base_url}/square/sidebar/admin/dashboard`;
    } else if (role === 'Manager' || role === Constants.SERVER) {
      window.location.href = `${environment.base_url}/square/sidebar/admin/waiterMenu/serverOrder`;
    } else if (role === Constants.TABLE) {
      window.location.href = `${environment.base_url}/square/sidebar/sidebar/order`;
    } else if (role === Constants.KIOSK) {
      window.location.href = `${environment.base_url}/square/kiosk`;
    }  else {
      this.router.navigate(['login'], { queryParamsHandling: 'preserve' }).then(() => {
      });
    }
  }

  reauthenticateRouter(buttonType) {
    if (buttonType === Constants.CLOVER) {
      this.reAuthenticateClover();
    } else if (buttonType === Constants.SQUARE) {
      this.reAuthenticateSquare();
    }
  }

  reAuthenticateSquare() {
    window.location.assign(`${environment.squareAuthUrl}?client_id=${environment.clientIdSquare}&scope=${environment.squarePermissions}&session=False&state=${environment.state}`); // reroutes to get oauth
  }

  reAuthenticateClover() {
      window.location.assign(`${environment.cloverAuthUrl}/oauth/authorize?client_id=${environment.client_id}`); // reroutes to get oauth
  }

  resetPassword() {
    this.passwordResetEmailMissing = false;
    const email = this.f.username.value;

    if (email) {
      this.authenticationService.generateOTC(email).subscribe(data => {
        this.router.navigate(['/reset-password',  { email: email, id: data.id, number: data.number}]);
      }, err => {
        this.errorMessage = err;
        setTimeout(() => {
          // set waited to true
          this.errorMessage = '';
        }, 7000);
      });
    } else {
      this.passwordResetEmailMissing = true;
    }
  }

  async getObject() {
    const ret = await Preferences.get({ key: 'currentUser' });
    const user = this._AESEncryptDecryptService.decrypt(ret);
    return user;
  }

  private async capacitorStoageSet() {
    const user = await this.getObject();
    if (user) {
      this.currentUser = JSON.parse(user);
      this.utilsService.setCurrentUser(this.currentUser);
      this.routeToProperComponent(this.currentUser.role[0]);
    }
  }

  async setCapacitorUser(value) {
    return await Preferences.set({
      key: 'user',
      value: value
    });
  }

  deleteQueryParameterFromCurrentRoute() {
    // this.location.replaceState('./order');
    this.location.replaceState(window.location.href.split('?')[0].split(window.location.href.split('/')[2])[1]);
  }
}
