<div class="d-flex justify-content-center p-2">
    <h1 class="font-weight-bold">New Device Login</h1>
</div>

<mat-dialog-content>
    <div class="dialog-container">
        <br>
        <!-- Tab Controls for Create Login and Manage Devices -->
        <div class="dialog-options-container">
            <mat-button-toggle-group [(ngModel)]="viewType" aria-label="View Type" (change)="onViewChange(viewType)">
                <mat-button-toggle value="tableInstructions">Table Device</mat-button-toggle>
                <mat-button-toggle value="createLogin">Generate Permanent Code</mat-button-toggle>
                <mat-button-toggle value="manageDevices">Manage Codes</mat-button-toggle>
            </mat-button-toggle-group>
            <div class="error-message">{{ errorMessage }}</div>
        </div>

        <!-- Create Login Content -->
        <div class="text-container" *ngIf="viewType === 'createLogin'">
            <ng-template *ngTemplateOutlet="createLogin"></ng-template>
        </div>

        <!-- Manage Devices Content -->
        <div class="text-container" *ngIf="viewType === 'manageDevices'">
            <ng-template *ngTemplateOutlet="manageDevices"></ng-template>
        </div>

        <!-- Instructions Content -->
        <div class="text-container" *ngIf="viewType === 'tableInstructions'">
            <ng-template *ngTemplateOutlet="tableInstructions"></ng-template>
        </div>
    </div>
</mat-dialog-content>

<ng-template #createLogin>
    <form>
        <div class="generated-otp-container">
            <label><b>Generated OTP:</b></label>
            <div class="otp-and-button">
                <input class="otp-input" type="text" [value]="otpGenerated ? kioskData.otp : ''" readonly/>
                <button type="button" class="btn btn-primary" (click)="generatePermanentCode()">Generate Permanent
                    Code
                </button>
            </div>

            <label><b>Role:</b></label>
            <select [(ngModel)]="kioskData.role" name="role" class="name-input" (change)="onRoleChange()">
                <option value="Jukebox" *ngIf="false">Jukebox</option>
                <option value="Kiosk" [selected]="isSelectedRole(Constants.KIOSK)">Kiosk</option>
                <option value="Table" [selected]="isSelectedRole(Constants.TABLE)">Table</option>
            </select>
        </div>


        <div class="kiosk-specific-container">
            <div class="payment-toggle">
                <mat-checkbox [(ngModel)]="kioskData.enablePayment" (click)="onEnablePaymentChange($event)" name="enablePayment">
                    Enable Payment
                </mat-checkbox>
            </div>&nbsp;&nbsp;

            <div class="side-by-side-container" *ngIf="(kioskData.role === Constants.KIOSK && kioskData.enablePayment === true)">
                <div class="category-selector-container">
                    <mat-button-toggle-group class="toggle-selector"
                                             *ngIf="kioskData.enablePayment"
                                             name="fontStyle"
                                             [value]="selectedPaymentType" aria-label="Font Style">
                        <mat-button-toggle
                                (change)="paymentTypeChange($event.value)"
                                *ngIf="false"
                                value="creditCardPayment">Credit Card
                        </mat-button-toggle>
                        <mat-button-toggle (change)="paymentTypeChange($event.value)"
                                           value="cloverConnector">Pay on {{currentUser.orgSettings.accountType}} Device
                        </mat-button-toggle>
                        <mat-button-toggle (change)="paymentTypeChange($event.value)" value="qrPayment">Scan To Pay
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

                <div class="employee-selector-container">
                        <div class="options-container" *ngIf="currentUser.orgSettings.accountType === Constants.CLOVER && kioskData.role === Constants.KIOSK && kioskData.enablePayment && kioskData.cloverConnectorPayment">
                            <mat-form-field>
                                <mat-label>Associate Clover Device For Payments</mat-label>
                                <mat-select [(ngModel)]="kioskData.cloverDeviceId" (selectionChange)="setDeviceSerial()"
                                            name="device">
                                    <mat-option value="">None</mat-option>
                                    <mat-option *ngFor="let device of cloverPaymentDevices" [value]="device.id">
                                        {{device.model + ' ' + device.serial}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <mat-form-field
                                            *ngIf="kioskData.cloverConnectorPayment">
                                <input focus matInput type="text" placeholder="Device Nickname"
                                       [ngModelOptions]="{standalone: true}"
                                       [(ngModel)]="kioskData.deviceAlias">
                                <mat-error>
                                    Clover Device Nickname is <strong>required</strong> for clover connector payment.
                                </mat-error>
                            </mat-form-field>
                        </div>
                </div>
            </div>




            <div class="side-by-side-container">
                <div class="category-selector-container">
                    <label><b>Excluded Categories:</b></label>
                    <mat-form-field>
                        <mat-select
                                placeholder="Select Categories"
                                [(ngModel)]="kioskData.excludedCategories"
                                name="excludedCategories"
                                multiple>
                            <mat-option *ngFor="let category of cloverCategories" [value]="category.id">
                                {{ category.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="employee-selector-container">
                    <label><b>Assign Employee:</b></label>
                    <mat-form-field>
                        <mat-select
                                placeholder="Select Employee"
                                [(ngModel)]="kioskData.assignedEmployee"
                                name="assignedEmployee"
                        >
                            <mat-option *ngFor="let employee of employees" [value]="employee.id">
                                {{ employee.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field class="example-full-width" *ngIf="currentUser.orgSettings.accountType === Constants.SQUARE">
                <mat-label>Location</mat-label>
                <mat-select formControlName="location">
                    <mat-option *ngFor="let location of locations"
                                [value]="location.id">{{location.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <!-- <div class="button-container">
          <button type="button" class="btn btn-primary" (click)="generatePermanentCode()">Generate Permanent Code</button>
        </div> -->
    </form>
</ng-template>

<ng-template #manageDevices>
    <div>
        <h3>Permanent Activation Codes</h3>
        <table class="permanent-codes-table">
            <thead>
            <tr>
                <th>Code</th>
                <th>Role</th>
                <th>Expires</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let code of permanentCodes; let i = index">
                <td>{{ code.code }}</td>
                <td>{{ code.role }}</td>
                <td>{{ code.expireAt ? code.expireAt : 'Permanent' }}</td>
                <td>
                    <button class="delete-btn" (click)="deleteCode(code.code)">X</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-template>
<ng-template #tableInstructions>
    <div class="card" style="width: 100%;">
        <div class="card-body">
            <h5 class="card-title">Instructions</h5>

            <p class="card-text">Choose instructions based on you operating system below. If you use a kiosk app, use
                the auto
                login to automatically log in to table kiosk on start. The URL and login details can also be found
                below:</p>
            <br>
            <div class="kiosk-instructions-container d-flex general-padding font-weight-bold linking">
                <div class="pr-3"><span><a href=""
                                           (click)="goToLink('https://learn.microsoft.com/en-us/windows/configuration/kiosk-single-app')">Windows</a></span>
                </div>
                <div class="pr-3"><span><a href=""
                                           (click)="goToLink('https://medium.com/@kanikasinha47/how-to-lock-android-tablet-or-phone-to-one-app-9aebe073b519#:~:text=Open%20the%20app%20you%20want%20to%20pin.&text=Swipe%20up%20on%20the%20app,until%20the%20screen%20is%20unpinned.')">Android</a></span>
                </div>
                <div class="pr-3">
                        <span><a href=""
                                 (click)="goToLink('https://www.hexnode.com/blogs/what-is-ios-kiosk-mode/')">IOS</a></span>
                </div>
            </div>
            <br>
            <div class="kiosk-instructions-container">


                <div class="table-selector-container">
                    <div style="color: black;font-weight:bold">For Specific Table Auto-Login URL: &nbsp; &nbsp;
                    </div>
                    <mat-form-field>
                        <mat-select name="roleSelect" placeholder="Select Table"
                                    (selectionChange)="addTableToAutologin($event.value)">
                            <mat-optgroup [label]="'MAIN DINING'">
                                <mat-option *ngFor="let table of tables" [value]="table.name">{{table.name}}
                                </mat-option>
                            </mat-optgroup>
                            <mat-optgroup *ngFor="let layout of currentUser.orgSettings.additionalLayouts | keyvalue"
                                          [label]="layout.key">
                                <mat-option *ngFor="let table of layout.value"
                                            [value]="table.name">{{table.name.split(layout.key + ' -
                                    ')[1].toLocaleLowerCase()}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <app-qr-generator [height]="200" [width]="200" [qrUrl]="qrUrl"
                                      [title]="('orderConfirmation.paymentQrTitle' | translate)"></app-qr-generator>
                </div>
            </div>
        </div>
    </div>
    <form>
        <h3>For Table Login</h3>
        <div class="generated-otp-container">
            <div class="general-padding font-weight-bold display-center-flex"><label><b>Autologin URL:</b></label>
                <input class="autologin-url-input" type="text" [value]="tableAutoLoginURL" readonly/></div>
            <br>
            <div class="or-container"><span>OR</span></div>
            <div class="general-padding font-weight-bold display-center-flex"><label><b> URL:</b></label>
                <input class="autologin-url-input" type="text" [value]="siteURL" readonly/></div>
            <div class="general-padding font-weight-bold display-center-flex"><label><b>Username:</b></label>
                <input class="otp-input" type="text" [value]="tableUsername" readonly/></div>
            <div class="otp-and-button">
                <label><b>Password:</b></label>
                <input class="otp-input" type="text" [value]="kioskData.otp" readonly/>
                <a class="password-reset" *ngIf="false" alt="password Reset" (click)="resetPassword()">
                    <mat-icon mat-icon class="delete-icon">lock_reset</mat-icon>
                </a> &nbsp; &nbsp;
                <b style="font-weight: bold;">OR</b> &nbsp; &nbsp; Generate Permanent Code
            </div>
            <div class=" pt-3"> I you want to login using OTP, Visit table {{environment.subdomainUrl}}.
                You may also login by visiting www{{environment.subdomainUrl}}/login and entering the credentials above.
            </div>
            <div class="note pt-3" color="warning"> Note: You may use either the one time code or Generate a Permanent
                Code for
                table login.
            </div>
        </div>
    </form>
</ng-template>
