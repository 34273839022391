import {QrOrderComponent} from './qr-order/qr-order.component';

import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './_guards';
import {KioskContainerComponent} from './otherRoles/kiosk/kiosk-container/kiosk-container.component';
import {TableSetupComponent} from './sidebar-menu/table/table-setup/table-setup.component';
import {HomeComponent} from './otherRoles/home/home.component';
import { LoginCodeComponent } from './login/login-code/login-code.component';
const appRoutes: Routes = [
  {
    path: 'order',
    component: KioskContainerComponent,
    loadChildren: () => import('./otherRoles/order/order.module').then(m => m.OrderModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'login-code',
    component: LoginCodeComponent ,
  },
 
  {
    path: 'youtube',
    loadChildren: () => import('./otherRoles/youtube-container/youtube-container.module').then(m => m.YoutubeContainerModule),
  },
  {
    path: 'tableSetup',
    component: TableSetupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'serverOrder',
    loadChildren: () => import('./sidebar-menu/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cloverAuth',
    loadChildren: () => import('./auth-router/clover-auth-router.module').then(m => m.CloverAuthRouterModule),
  },
  {
    path: 'sidebar',
    loadChildren: () => import('./sidebar-menu/sidebar-menu.module').then(m => m.SidebarMenuModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'paymentUser',
    component: KioskContainerComponent,
    loadChildren: () => import('./otherRoles/paymentUser/payment-user/paymentUser.module').then(m => m.PaymenUserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rewards',
    loadChildren: () => import('./otherRoles/rewardsUser/rewards-user.module').then(m => m.RewardsUserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orderUser',
    component: KioskContainerComponent,
    loadChildren: () => import('./otherRoles/orderUser/order-user/orderUser.module').then(m => m.OrderUserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-kiosk',
    component: KioskContainerComponent,
    loadChildren: () => import('./otherRoles/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./otherRoles/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kiosk',
    component: KioskContainerComponent,
    loadChildren: () => import('./otherRoles/kiosk/kiosk-component/kiosk.module').then(m => m.KioskModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'QROrder',
    component: QrOrderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: HomeComponent,
  },


  // otherwise redirect to home
  {path: '**', redirectTo: 'cloverAuth/cloverAuth'}
  // cloverAuth/cloverAuth
];

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });

