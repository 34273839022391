<div class="d-flex justify-content-center p-2">
  <h1 class="font-weight-bold">Login Table & Device</h1>
</div>
<mat-dialog-content>
  <br>
  <div class="dialog-options-container">
    <mat-button-toggle-group [(ngModel)]="popupType" aria-label="Font Style">
      <mat-button-toggle value="link">Link</mat-button-toggle>
      <mat-button-toggle value="qr">QR Code</mat-button-toggle>
      <mat-button-toggle value="settings">Settings</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="error-message">{{errorMessage}}</div>
  </div>
  <div class="text-container" *ngIf="popupType === 'settings'">
    <ng-template *ngTemplateOutlet="settings"></ng-template>
  </div>
  <div class="text-container" *ngIf="popupType === 'link'">
    <ng-container *ngTemplateOutlet="linkData"></ng-container>
  </div>
  <div class="text-container-qr" *ngIf="popupType === 'qr'">
    <ng-container *ngTemplateOutlet="qrData"></ng-container>
  </div>
</mat-dialog-content>


<ng-template #linkData>
  <div class="card" style="width: 100%;">
    <div class="card-body">
      <h5 class="card-title">Instructions</h5>

      <p class="card-text">Choose instructions based on you operating system below. If you use a kiosk app, use the auto
        login to automatically log in to table kiosk on start. The URL and login details can also be found below:</p>
      <div class="d-flex general-padding font-weight-bold linking">
        <div class="pr-3">
          <span><a href="" (click)="goToLink(siteAutoLoginURL)">Auto login URL</a></span>
        </div>
        <div class="pr-3">
          <span><a href="" (click)="goToLink(siteURL)">Site URL</a></span>
        </div>
        <div class="pr-3">
          <span><a href=""
              (click)="goToLink('https://learn.microsoft.com/en-us/windows/configuration/kiosk-single-app')">Windows</a></span>
        </div>
        <div class="pr-3">
          <span><a href=""
              (click)="goToLink('https://medium.com/@kanikasinha47/how-to-lock-android-tablet-or-phone-to-one-app-9aebe073b519#:~:text=Open%20the%20app%20you%20want%20to%20pin.&text=Swipe%20up%20on%20the%20app,until%20the%20screen%20is%20unpinned.')">Android</a></span>
        </div>
        <div class="pr-3">
          <span><a href="" (click)="goToLink('https://www.hexnode.com/blogs/what-is-ios-kiosk-mode/')">IOS</a></span>
        </div>
      </div>

      <div class="table-selector-container">
        <div style="color: black;font-weight:bold">For Specific Table Auto-Login URL: &nbsp; &nbsp;
        </div>
        <mat-form-field>
          <mat-select name="roleSelect" placeholder="Select Table"
            (selectionChange)="addTableToAutologin($event.value)">
            <mat-optgroup [label]="'MAIN DINING'">
              <mat-option *ngFor="let table of tables" [value]="table.name">{{table.name}}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup *ngFor="let layout of currentUser.orgSettings.additionalLayouts | keyvalue"
              [label]="layout.key">
              <mat-option *ngFor="let table of layout.value" [value]="table.name">{{table.name.split(layout.key + ' -
                ')[1].toLocaleLowerCase()}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="general-padding font-weight-bold">Username: <span class="creds">{{tableUsername}}</span></div>
      <div class="general-padding font-weight-bold">One Time Password: <span class="creds"> &nbsp; {{tablePassword}}
          &nbsp; &nbsp;
          <a class="password-reset" *ngIf="false" alt="password Reset" (click)="resetPassword()">
            <mat-icon mat-icon class="delete-icon">lock_reset</mat-icon>
          </a> &nbsp; &nbsp;
          <b style="font-weight: bold;">OR</b> &nbsp; &nbsp; Admin's Password</span>
        &nbsp; &nbsp;
      </div>

      <div class="note pt-3"> Note: You may use either the one time code or Admin's password for table login. If you
        want
        to login automatically, replace the password in the autologin url to the admins account password.
      </div>
    </div>
  </div>
</ng-template>

<ng-template #qrData>
  <div class="card p-3">
    <div class="card-text">Scan the QR code with the device you would like to login.</div><br>
    <div class="table-selector-container">
      <div class="card-text">For Table Specific Login:
      </div>&nbsp; &nbsp;
      <mat-form-field>
        <mat-select name="roleSelect" placeholder="Select Table" (selectionChange)="addTableToAutologin($event.value)">
          <mat-optgroup [label]="'MAIN DINING'">
            <mat-option *ngFor="let table of tables" [value]="table.name">{{table.name}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup *ngFor="let layout of currentUser.orgSettings.additionalLayouts | keyvalue"
            [label]="layout.key">
            <mat-option *ngFor="let table of layout.value" [value]="table.name">{{table.name.split(layout.key + ' -
              ')[1].toLocaleLowerCase()}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <app-qr-generator [height]="200" [width]="200" [qrUrl]="qrUrl"
      [title]="('orderConfirmation.paymentQrTitle' | translate)"></app-qr-generator>
  </div>
</ng-template>

<ng-template #settings>
  <div class="card">
    <div class="d-flex justify-content-center">
      <form class="example-form">
        <div class="select-container">
          <mat-slide-toggle [ngModelOptions]="{standalone: true}" [(ngModel)]="paymentEnabled" class="card-text">Payment
            Enabled: {{paymentEnabled}}</mat-slide-toggle>
        </div>
        <div class="toggle-selector">
          <ng-multiselect-dropdown [placeholder]="'Excluded Categories'" [settings]="excludedDropdownSettings"
            [data]="cloverCategories" [ngModelOptions]="{standalone: true}" [(ngModel)]='selectedCategories'
            (onDeSelect)="onExcludeCategoryDeSelect($event)" (onSelect)="onExcludeCategorySelect($event)" multiple>
            <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
            </ng-template>
          </ng-multiselect-dropdown>
          <br><br><br>
        </div>
      </form>
    </div>
  </div>
</ng-template>
