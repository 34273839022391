import {Component, Inject, OnInit} from '@angular/core';
import { ActivationCodeService } from 'src/app/activation-code.service'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Constants} from '../../../constants/constants';
import {environment} from '../../../../environments/environment';
import {Category} from '../../../_models/category';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {TakePayment} from '../../../_models/takePayment';
import {OrgTable} from '../../../_models/orgTable';
import {UtilsService} from '../../../_services/utils.service';
import { HttpClient } from '@angular/common/http';
import { OTPLoginService } from 'src/app/_services/OTPLogin.service';

@Component({
    selector: 'app-connect-table-login',
    templateUrl: './connect-login-table-popup.html',
    styleUrls: ['./connect-login-table-popup.component.scss']
})
export class ConnectLoginTableComponent implements OnInit {

    description: string;
    message: string;
    currentUser: User;
    currentTableName: string;
    errorMessage: string;
    selectedRole: string;
    loading = false;
    assignedTables = false;
    inputValue = '';
    table: User;
    paymentEnabled = false;
    button_text = 'Submit';
    popupType = 'link';
    siteURL = `https://dinengo.net`;
    siteAutoLoginURL = `https://dinengo.net/login?username=username&password=password`;
    qrUrl = `https://dinengo.net/cloverAuth/cloverAuth?qr=`;
    tableUsername = '';
    tablePassword = Math.random().toString(36).slice(-8);
    cloverCategories: Category[];
    excludedCategories: any[] = [];
    selectedCategories: any[] = [];
    excludedDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'name',
        textField: 'name',
        itemsShowLimit: 2,
        allowSearchFilter: true,
        enableCheckAll: false
    };

    tables: OrgTable[] = [];

    constructor(private OTPlogin: OTPLoginService, private http: HttpClient, private dialogRef: MatDialogRef<ConnectLoginTableComponent>, private cloverOrderService: CloverOrderService,
                private alertService: AlertService, private userService: UserService, private router: Router,
                private cloverAPIService: CloverApiService, private utilsService: UtilsService,
                private _AESEncryptDecryptService: AESEncryptDecryptService, @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.title;
        this.message = data.message;
        this.button_text = data.button_text;
        this.currentTableName = data.currentTableName;
        this.currentUser = utilsService.getCurrentUser();
        this.selectedRole = this.currentUser?.selectedRole;
        if (!environment.production) {
            this.siteURL = `https://dine-n-go-dev.herokuapp.com`;
            this.siteAutoLoginURL = `https://dine-n-go-dev.herokuapp.com/login?email=username&password=password`;
            this.qrUrl = `https://dine-n-go-dev.herokuapp.com/cloverAuth/cloverAuth?qr=`;
        }
    }

    ngOnInit() {
        if (this.currentUser.orgSettings.accountType === Constants.CLOVER) {
            this.loadAllCategories();
        }
        this.getOrCreateTableLogin();
        this.getAllTables();
    }

    private loadAllCategories() {
        this.cloverAPIService.getAllCategories().subscribe(category => {
            this.cloverCategories = category;
            this.loading = false;
            this.selectedCategories = this.getSelectedCategoriesList(this.excludedCategories ?
                this.excludedCategories : [], this.cloverCategories);
            this.excludedCategories = this.getSelectedCategoriesList(this.excludedCategories ?
                this.excludedCategories : [], this.cloverCategories).map( cloverCategory =>  cloverCategory.name);
        });
    }

    getAllTables() {
        this.tables = this.currentUser.orgSettings.tables;
    }

    getSelectedCategoriesList(currentCategoriesList: any, optionsList: any) {
        const selectedOptions = [];
        currentCategoriesList?.map((name) => {
            const x = optionsList.filter((option: any, ind: number) => option.name === name);
            selectedOptions.push(...x);
        });
        return selectedOptions;
    }


    getOrCreateTableLogin(update?) {
        const user = new User();

        if (update) {
            const takePayment = new TakePayment();
            takePayment.paymentRequired = this.paymentEnabled;
            takePayment.devicePaymentEnabled = this.paymentEnabled;
            takePayment.scanToPayPayment = this.paymentEnabled;
            user.excludedCategories = this.excludedCategories;
            user.takePayment = takePayment;
        }
        this.tablePassword = Math.random().toString(36).slice(-6);
        this.OTPlogin.storeActivationCodeAndRole(this.tablePassword, 'Table', this.paymentEnabled, user.excludedCategories);
        sessionStorage.setItem('activationCode', this.tablePassword);

        this.userService.getOrCreateTableLogin( this.tablePassword, update ? user : undefined).subscribe(table => {
            this.paymentEnabled = table.takePayment.devicePaymentEnabled;
            this.tableUsername = table.username;
            this.excludedCategories = table.excludedCategories;
            this.selectedCategories = table.excludedCategories;
            if (!environment.production) {
                this.siteAutoLoginURL = `https://dine-n-go-dev.herokuapp.com/login?email=` + this.tableUsername + `&password=`
                    + this.tablePassword;
                this.qrUrl = `https://dine-n-go-dev.herokuapp.com/login?email=` + this.tableUsername + `&password=` + this.tablePassword;
            } else {
                this.siteAutoLoginURL = `https://dinengo.net/login?email=` + this.tableUsername + `&password=` + this.tablePassword;
                this.qrUrl = `https://dinengo.net/login?email=` + this.tableUsername + `&password=` + this.tablePassword;
                // this.qrUrl = `${environment.qrOrderURL}` + this._AESEncryptDecryptService.encrypt(JSON.stringify(table));
            }
            this.table = table;
            this.popupType = 'link';
        });
    }

    close() {
        this.dialogRef.close();
    }

    goToLink(url: string) {
        window.open(url, '_blank');
    }

    submit() {
        this.dialogRef.close({
            customerName: this.inputValue,
        });
    }

    onExcludeCategorySelect(item: any) {
        const selectedItem = this.cloverCategories.filter((value) => value.name === item.name)[0];
        if (!this.excludedCategories.includes(selectedItem.name)) {
            this.excludedCategories.push(selectedItem.name.toString());
        }
    }

    onExcludeCategoryDeSelect(item: any) {
        const exists = this.excludedCategories.includes(item.name);
        if (exists) {
            this.excludedCategories = this.excludedCategories.filter((name: string) => name !== item.name);
        }
    }

    resetPassword() {
        if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager') {
            const passwordInput = prompt('Enter New Password:');
            if (passwordInput !== null && passwordInput !== '') {
                if (passwordInput.length > 6) {
                    this.table.password = passwordInput.toString();
                    this.userService.update(this.table).pipe(first()).subscribe(() => {
                            this.getOrCreateTableLogin();
                        },
                        error => {
                            this.alertService.error(error);
                            this.loading = false;
                        });
                } else {
                    throwError('Password too short');
                }
            }
        } else {
            throwError('You are not authorized to perform this action!');
        }
    }

    addTableToAutologin(item: any) {

        if (this.siteAutoLoginURL.includes('?table=')) {
            this.siteAutoLoginURL = this.siteAutoLoginURL.substring(0, this.siteAutoLoginURL.indexOf('?table='));
        }
        this.siteAutoLoginURL = this.siteAutoLoginURL + '?table=' + item.replace(/\s/g, '');

        if (this.qrUrl.includes('&table=')) {
            this.qrUrl = this.qrUrl.substring(0, this.qrUrl.indexOf('&table='));
        }
        this.qrUrl = this.qrUrl + '&table=' + item.replace(/\s/g, '');
    }
}
