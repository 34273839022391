import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PaymentPayload} from '../_models/paymentPayload';
import {AESEncryptDecryptService} from "./aesencrypt-decrypt.service";

@Injectable({
  providedIn: 'root'
})
export class CloverPayServiceService {

  constructor(private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService) { }

  processPayment(paymentPayload: PaymentPayload) {
    return this.http.post<any>(`${environment.apiUrl}/cloverPay/getAll`, paymentPayload);
  }

  processPaymentV2(paymentPayload) {
    return this.http.post<any>(`${environment.apiUrl}/cloverPay/processPayment`, paymentPayload);
  }

  processIframePayment(paymentPayload) {
    return this.http.post<any>(`${environment.apiUrl}/cloverPay/processIframePayment`, paymentPayload);
  }

  updatePayment(paymentPayload) {
    return this.http.post<any>(`${environment.apiUrl}/cloverPay/updatePayment`, paymentPayload);
  }

  getCloverConnectedDevices() {
    return this.http.get<any>(`${environment.apiUrl}/cloverPay/getCloverConnectorDevices`);
  }

  getAPIKey() {
    return this.http.get<any>(`${environment.apiUrl}/cloverPay/getapikey`);
  }

}
